import { API } from 'aws-amplify'

export default {
  namespaced: true,
  state: {
    branchContactDetails: '',
    staffContactDetails: '',
    staffMembersLoadedState : false,
    branchContactsLoadedState : false
  },
  mutations: {
    SET_BRANCH_CONTACT_DETAILS(state, data) {
      state.branchContactDetails = [data]
    },
    SET_STAFF_CONTACT_DETAILS(state, data){
      state.staffContactDetails = data;
    },
    SET_STAFF_LOADED_STATE(state, data){
      state.staffMembersLoadedState = data;
    },
    SET_BRANCH_CONTACTS_LOADED_STATE(state, data){
      state.branchContactsLoadedState = data;
    }
  },
	actions :  {
    getBranchContactDetails({commit, rootState}){
      commit('SET_BRANCH_CONTACTS_LOADED_STATE', false)
      API.get('contractorAppMainRest', `/get?query=/${rootState.selectedAccount.CompanyShortName}/contacts/${rootState.selectedAccount.PersonOID}/branch`)
      .then(res => {
        commit('SET_BRANCH_CONTACT_DETAILS', res.data)
        commit('SET_BRANCH_CONTACTS_LOADED_STATE', true)
      })
    },
    getStaffContactDetails({commit, rootState}){
      commit('SET_STAFF_LOADED_STATE', false);
      API.get('contractorAppMainRest', `/get?query=/${rootState.selectedAccount.CompanyShortName}/contacts/${rootState.selectedAccount.PersonOID}/staff`)
      .then(res => {
        commit('SET_STAFF_CONTACT_DETAILS', res.data)
        commit('SET_STAFF_LOADED_STATE', true);
      })
      },
  },
  getters:{
    branchContactDetails: state => {
      return state.branchContactDetails;
    },
    staffMembers: state => {
      return state.staffContactDetails.Data;
    },
    staffMembersLoaded: state => {
      return state.staffMembersLoadedState;
    },
    branchContactsLoaded: state => {
      return state.branchContactsLoadedState;
    }
  }
}
