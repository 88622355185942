var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-snackbar',{attrs:{"timeout":_vm.timeout,"color":_vm.snackbarColor},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"white","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarText)+" ")]),_c('v-card',{staticClass:"mb-6"},[_c('v-card-title',{staticClass:"secondary white--text pa-3",attrs:{"id":"card-title"}},[_c('div',{},[_c('v-icon',{staticClass:"mr-3",attrs:{"color":"white"}},[_vm._v("mdi-hammer-wrench")]),_c('span',{},[_vm._v("Maintenance Jobs")])],1)]),(!_vm.maintenanceJobsLoaded)?_c('div',[_c('LoadingTable')],1):_c('v-data-table',{staticClass:"summaryTable",attrs:{"headers":_vm.maintenanceHeaders,"items":_vm.maintenanceJobs,"items-per-page":10,"mobile-breakpoint":962,"sort-by":"ReportedAt","sort-desc":"","search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.ReportedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.ReportedAt))+" ")]}},{key:"item.JobID",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.JobID)+" ")]}},{key:"item.Address",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.Address)+" ")]}},{key:"item.Priority",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.Priority)+" ")]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.Description)+" ")]}},{key:"item.photos",fn:function(ref){
var item = ref.item;
return [(item.Photos.length > 0)?_c('v-btn',{attrs:{"text":"","color":"grey darken-1","width":"90"},on:{"click":function($event){return _vm.openPopup(item.Photos)}}},[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-camera")])],1):_c('div',[_c('v-tooltip',{attrs:{"open-delay":"500","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"red"}},on),[_vm._v("mdi-camera-off")])]}}],null,true)},[_c('span',[_vm._v("No photos uploaded for job")])])],1)]}},{key:"item.IsCompletedInApp",fn:function(ref){
var item = ref.item;
return [(item.IsCompletedInApp)?_c('v-btn',{attrs:{"text":"","color":"green","width":"90"},on:{"click":function($event){return _vm.markJobIncomplete(item.OID)}}},[_c('span',{staticClass:"font-weight-light"},[_vm._v(" Completed ")])]):_c('v-btn',{attrs:{"text":"","color":"red","width":"90"},on:{"click":function($event){return _vm.markJobComplete(item.OID)}}},[_c('span',{staticClass:"font-weight-light"},[_vm._v(" Pending ")])])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","outlined":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-file-tree")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',[_c('v-btn',{attrs:{"outlined":"","text":"","color":"grey menu-button"},on:{"click":function($event){return _vm.downloadJobSheet(item.OID, item.JobID)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-clipboard-list-outline")]),_vm._v(" Job Sheet ")],1)],1),_c('v-list-item',[_c('v-tooltip',{attrs:{"open-delay":"2500","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"outlined":"","text":"","color":"grey"},on:{"click":function($event){return _vm.openNotePopup(item.OID)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-comment-text-outline")]),_vm._v(" Add Note ")],1)]}}],null,true)},[_c('span',[_vm._v("Add a note for this maintenance job")])])],1),_c('v-list-item',[_c('v-tooltip',{attrs:{"open-delay":"2500","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"outlined":"","text":"","color":"grey"},on:{"click":function($event){return _vm.openInvoicePopup(item.OID, item.JobID)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-clipboard-check")]),_vm._v(" Submit Invoice ")],1)]}}],null,true)},[_c('span',[_vm._v("Submit a new invoice for this maintenance job")])])],1)],1)],1)]}},{key:"item.jobsheet",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"outlined":"","small":"","text":"","color":"grey"},on:{"click":function($event){return _vm.downloadJobSheet(item.OID, item.JobID)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-clipboard-list-outline")])],1)]}},{key:"item.note",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"open-delay":"2500","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"outlined":"","small":"","text":"","color":"grey"},on:{"click":function($event){return _vm.openNotePopup(item.OID)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-comment-text-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Add a note for this maintenance job")])])]}},{key:"item.invoice",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"open-delay":"2500","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"outlined":"","small":"","text":"","color":"grey"},on:{"click":function($event){return _vm.openInvoicePopup(item.OID, item.JobID)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-clipboard-check")])],1)]}}],null,true)},[_c('span',[_vm._v("Submit a new invoice for this maintenance job")])])]}}],null,true)}),_c('p',{staticClass:"description"},[_vm._v("*Only displays maintenance jobs reported in the past 90 days")])],1),_c('ImageCarouselPopup',{attrs:{"dialog":_vm.dialog,"photos":_vm.photos},on:{"closeDialog":_vm.closePopup}}),_c('SubmitNotePopup',{attrs:{"dialog":_vm.submitNotePopup,"OID":_vm.maintenanceJobID},on:{"closeDialog":_vm.closeNotePopup,"msgSent":_vm.msgSent}}),_c('SubmitInvoicePopup',{attrs:{"dialog":_vm.newInvoicePopup,"OID":_vm.maintenanceJobID,"jobNumber":_vm.jobNumber},on:{"closeDialog":_vm.closeInvoicePopup,"invoiceSent":_vm.invoiceSent}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }