<template>
  <div app class="main-page grey lighten-4 pa-0">
    <Navbar/>
    <v-content>
 <!--     <Snackbar v-if="errorMessages.length" class="snackbar" /> -->
 <v-container  class="pa-0">
   <v-row v-if="returnUserFirstName != null">
              <v-col class="" cols="6" sm="8">
                <span 
                  class="font-weight-light grey--text text-capitalize" 
                  :class="{'body-1': $vuetify.breakpoint.xsOnly,'headline': $vuetify.breakpoint.smAndUp}"
                >{{returnUserFirstName}}</span>
              </v-col>
        </v-row>
      <router-view />
 </v-container>

</v-content>
       <Footer id="footer"/>
        <!-- Logout snackbar -->
    <v-snackbar
      class="mb-12 mx-2"
      color="red"
      v-model="dialog"
      vertical
      :timeout="timeout"
      
    >
      <v-row>
        <v-col cols="12">
          <h2>Auto logout</h2>
          <p>You will be logged out in {{countDown}} seconds...</p>
        </v-col>
        <v-col cols="8" offset="2">
          <v-btn
            block
            color="green"
            dark
            @click="closeDialog"
          >
            Stay logged in?
          </v-btn>          
        </v-col>
      </v-row>
    </v-snackbar>
  </div>
</template>

<script>
import Navbar from '@/components/NavigationComponent.vue'
import Footer from '@/components/TheFooter.vue'
import {mapState} from 'vuex'
import {mapGetters} from 'vuex'
import { Auth } from 'aws-amplify';

export default {
  components: {
    Navbar,
    Footer
  },
  data() {
    return {
      dialog: false,
      userActivityThrottlerTimeout: null,
      userActivityTimeout: null,
      countDown: 20,
      timeout: 1200000
    }
  },
  computed: {
    ...mapState(['errorMessages']),
    ...mapGetters(['loggedIn',
    'returnUserFirstName'])
  },
  mounted() {
    // let zendesk = document.createElement('script')
    // zendesk.setAttribute('src', 'https://v2.zopim.com/?hQmsYpr22Lyxmrakbvh94szVGC7lqQIe')
    // document.head.appendChild(zendesk)
  },
  created() {

  },
  methods: {
    activateActivityTracker() {
      window.addEventListener("mousemove", this.userActivityThrottler)
      window.addEventListener("scroll", this.userActivityThrottler)
      window.addEventListener("keydown", this.userActivityThrottler)
      window.addEventListener("resize", this.userActivityThrottler)
    },
    resetUserActivityTimeout() {
      clearTimeout(this.userActivityTimeout)
      this.userActivityTimeout = setTimeout(() => {
        this.inactiveUserAction()
        // 1 min = 60000
        // 3 mins = 180000
        //10 mins = 600000
        //20 mins = 1200000
      }, 1200000);
    },
    userActivityThrottler() {
      if (!this.userActivityThrottlerTimeout) {
          this.userActivityThrottlerTimeout = setTimeout(() => {
          this.resetUserActivityTimeout()
          clearTimeout(this.userActivityThrottlerTimeout)
          this.userActivityThrottlerTimeout = null
        }, 1000)
      }
    },
    inactiveUserAction() {
      // logout logic
      // this.isInactive = true
      this.dialog = true
      this.countDownTimer()
    },
    countDownTimer() {
      if(this.dialog && this.countDown > 0) {
          setTimeout(() => {
              this.countDown -= 1
              this.countDownTimer()
          }, 1000)
      }
    },
    closeDialog() {
      this.dialog = false
      // this.isInactive = false
      this.countDown = 21
    }
  },
  watch: {
    countDown(val) {
      if(val === 0) {
        this.$store.commit('LOGOUT')
          this.dialog = false
          this.countDown = 20
          Auth.signOut().then(() => {
            const path = '/'
            if (this.$route.path !== path)
            this.$router.push(path)
          })
      }
    },
  },
  beforeMount() {
    this.activateActivityTracker();
  },
  beforeDestroy() {
    window.removeEventListener("mousemove", this.userActivityThrottler);
    window.removeEventListener("scroll", this.userActivityThrottler);
    window.removeEventListener("keydown", this.userActivityThrottler);
    window.removeEventListener("resize", this.userActivityThrottler);
  
    clearTimeout(this.userActivityTimeout);
    clearTimeout(this.userActivityThrottlerTimeout);
  },  
}
</script>

<style>
.summaryCell{
  width: 33% !important;
  text-align: center !important;
}
  .snackbar {
    margin-bottom: 3rem;
  }
  #footer {
    margin-top: 105px;
  }
  .description{
    font-size:10px;
}
</style>