/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getContractorAccount = /* GraphQL */ `
  query GetContractorAccount($id: ID!) {
    getContractorAccount(id: $id) {
      id
      PersonOID
      CompanyName
      CreatedDate
      CompanyShortName
      FirstName
      Surname
      GlobalReference
      ContractorCompany
      ManagingBranch
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listContractorAccounts = /* GraphQL */ `
  query ListContractorAccounts(
    $filter: ModelContractorAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContractorAccounts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        PersonOID
        CompanyName
        CreatedDate
        CompanyShortName
        FirstName
        Surname
        GlobalReference
        ContractorCompany
        ManagingBranch
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getContractorAccountSyncDateTime = /* GraphQL */ `
  query GetContractorAccountSyncDateTime($id: ID!) {
    getContractorAccountSyncDateTime(id: $id) {
      id
      lastUpdate
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listContractorAccountSyncDateTimes = /* GraphQL */ `
  query ListContractorAccountSyncDateTimes(
    $filter: ModelContractorAccountSyncDateTimeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContractorAccountSyncDateTimes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        lastUpdate
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
