<template>
    <v-dialog
      v-model="invoiceDialog"
      max-width="80%"
    >
      <v-card>
        <v-card-title class="primary">
          <span class="headline">Pending Invoice</span>
          <v-spacer></v-spacer>
          <v-btn text @click="closePendingInvoice()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text v-if="pendingInvoice">
          <template>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th width="10%" class="text-left  subtitle-1 font-weight-bold">Money In</th>
                    <th class="text-left"></th>
                    <th width="10%"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in pendingInvoice.MoneyIn" :key="item.name">
                    <td>{{ item.TransactionDate | formatDate }}</td>
                    <td>{{ item.TransactionDescription }}</td>
                    <td>{{ item.TransactionAmount | toCurrency(currencyValues)}}</td>
                  </tr>
                  <tr class="font-weight-bold">
                    <td></td>
                    <td class="text-right">Total</td>
                    <td>{{pendingInvoice.MoneyInAmount | toCurrency(currencyValues)}}</td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </template>
          <template>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th width="10%" class="text-left  subtitle-1 font-weight-bold">Money Out</th>
                    <th class="text-left"></th>
                    <th width="10%"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in pendingInvoice.MoneyOut" :key="item.name">
                    <td>{{ item.TransactionDate | formatDate }}</td>
                    <td>{{ item.TransactionDescription }}</td>
                    <td>{{ item.TransactionAmount | toCurrency(currencyValues) }}</td>
                  </tr>
                  <tr class="font-weight-bold">
                    <td></td>
                    <td class="text-right">Total</td>
                    <td>{{pendingInvoice.MoneyOutAmount | toCurrency(currencyValues)}}</td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </template>
          <template>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th width="10%" class="text-left"></th>
                    <th class="text-left"></th>
                    <th width="10%" class="text-left"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="font-weight-bold">
                    <td></td>
                    <td class="text-right title">Invoice Total</td>
                    <td class="title">{{pendingInvoice.TotalInvoiceAmount | toCurrency(currencyValues)}}</td>
                  </tr>
                </tbody>  
                </template>
              </v-simple-table>
            </template>
        </v-card-text>
      </v-card>
    </v-dialog>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  props: [
    'invoiceDialog'
  ],
  data() {
    return {

    }
  },
  methods: {
    closePendingInvoice() {
      this.$emit('closeDialog');
    }
  },
  computed:{
    ...mapGetters({
      pendingInvoice : 'Finances/pendingInvoice',
      currencyValues : 'currencyInformation'
    }),
  },
  filters: {
    toCurrency: function (value, currencyData) {
    if (typeof value !== "number") {
            return value;
        }
        if(currencyData != undefined){
        var formatter = new Intl.NumberFormat(currencyData.LocaleCode, {
            style: 'currency',
            currency: currencyData.CurrencyCode,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
        return formatter.format(value);
        }
        else{
          return null;
        }
    }
  }
}
</script>