<template>
        <v-card class="mb-6">
            <v-card-title class="secondary white--text pa-3" id="card-title">
            <div class="">
                <v-icon class="mr-3" color="white" >mdi-account-circle</v-icon>
                <span class="">{{account.CompanyName}}</span>
            </div>
        </v-card-title>
        <div class="account-card-content">
        <p>Contractor account with {{account.ManagingBranch}}</p>
        <p><strong>Account Name:</strong> {{account.Firstname}} {{account.Surname}} {{account.ContractorCompany}}</p>
        <p><strong>Account ID:</strong> {{account.GlobalReference}}</p>
                <v-btn
          color="green"
          width="100"
          class="mr-3 white--text select-button"
          @click="selectAccount(account)"
          >Select</v-btn
        >
        </div>
    </v-card>
</template>
<script>


export default {
  props: [
    'account'
  ],
   components: {

  },
  data() {
    return {

    }
  },
  methods:{
      selectAccount(selectedAccount){
          this.$store.commit('SET_SELECTED_ACCOUNT', selectedAccount);
          this.$store.dispatch('getCurrencyData');
          this.$router.push('/jobs')
      }
  }
}
</script>
<style scoped>
.account-card-content{
    padding:10px;
}
.select-button{
    position: relative;
    /* float: right; */
    display: block!important;
    left: 80%;
}
</style>