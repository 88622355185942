<template>
    <div>
        <v-card class="mb-6">
            <v-card-title class="secondary white--text pa-3" id="card-title">
            <div class="">
                <v-icon class="mr-3" color="white" >mdi-account-supervisor</v-icon>
                <span class="">Staff Contact Details</span>
            </div>
            </v-card-title>
            <div v-if="!staffMembersLoaded">
              <LoadingTable />
            </div>
            <v-data-table v-else class="summaryTable"
                :headers="staffMembersHeaders"
                :items="staffMembers"
                :items-per-page="10"
                :mobile-breakpoint="768"
                :search="search"
            >
          <template v-slot:top>
        <v-text-field
          v-model="search"
          label="Search"
          class="mx-4"
        ></v-text-field>
      </template>
      <!-- Reported At Column -->
      <template v-slot:[`item.Name`]="{ item }">
        {{item.Name}}
      </template>
      <!-- Reference Column -->
      <template v-slot:[`item.JobTitle`]="{ item }">
        {{item.JobTitle}}
      </template>
          <!-- Address Column -->
      <template v-slot:[`item.Landline`]="{ item }">
        <a v-bind:href="'tel:' + item.Landline">{{ item.Landline }}</a>
      </template>
        <!-- Priority Column -->
      <template v-slot:[`item.Mobile`]="{ item }">
        <a v-bind:href="'tel:' + item.Mobile">{{ item.Mobile }}</a>
      </template>
      <!-- Description Column -->
      <template v-slot:[`item.Email`]="{ item }">
        <a v-bind:href="'mailto:' + item.Email">{{ item.Email }}</a>
      </template>
        </v-data-table>
        </v-card>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import LoadingTable from '../components/SkeletonLoaders/SkeletonLoaderTable.vue'

export default {
  components:{
    LoadingTable
  },
  data() {
    return {
        staffMembersHeaders: [
          { text: 'Name', value:'Name', align:"center" },
          { text: 'JobTitle', value: 'JobTitle', align:"center" },
          { text: 'Landline', value: 'Landline', align:"center" },
          { text: 'Mobile', value: 'Mobile', align:"center" },
          { text: 'Email', value: 'Email', align:"center" },
        ],
        search: ''
    }
  },
  computed: {
    ...mapGetters({
        staffMembers: "Contacts/staffMembers",
        staffMembersLoaded : "Contacts/staffMembersLoaded"
    }),
  },
  created() {
      this.$store.dispatch("Contacts/getStaffContactDetails");
  }
}
</script>

<style scoped>

</style>
