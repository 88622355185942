import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index.js'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import './registerServiceWorker'
import moment from 'moment/src/moment'
import VueFriendlyIframe from 'vue-friendly-iframe'
import VueCarousel from 'vue-carousel';
// AWS AMPLIFY 
import Amplify from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';
import awsconfig from '../src/aws-exports';
import '@aws-amplify/ui-vue';

Amplify.configure(awsconfig);
Auth.configure(awsconfig)

Vue.config.productionTip = false
Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(String(value)).format('DD/MM/YYYY')
  }
})

Vue.use(VueFriendlyIframe)
Vue.use(VueCarousel);

new Vue({
  router,
  store,
  vuetify,
  //sets user state to current localstorage in
  // case of refresh
  created() {

    // *** saftey measure to avoid use of fake tolkens
    axios.interceptors.response.use(
      response => response,
      error => {
        if (error.response.status === 401) {
          this.$store.dispatch('logout')
        }
        return Promise.reject(error)
      }
    )
  },
  render: h => h(App)
}).$mount('#app')