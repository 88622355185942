<template>
<div>
        <SummaryComponent />
      <RemittanceTableComponent />
    </div>
</template>
<script>
import RemittanceTableComponent from '../components/RemittanceTableComponent.vue'
import SummaryComponent from '../components/SummaryComponent.vue'

export default {
  components: {
    RemittanceTableComponent,
    SummaryComponent
  }
}
</script>