<template>
    <div>
        <v-card class="mb-6">
            <v-card-title class="secondary white--text pa-3" id="card-title">
            <div class="">
                <v-icon class="mr-3" color="white" >mdi-bank</v-icon>
                <span class="">Account Summary</span>
            </div>
            </v-card-title>
            <!-- Desktop & Tablet Table -->
            <div v-if="!summaryLoaded">
              <LoadingTable />
            </div>
            <div v-else>
            <v-simple-table v-if="windowWidth > 700">
            <template>
                <thead>
                    <tr>
                    <th class="text-center">Name</th>
                    <th class="text-center">Balance</th>
                    <th class="text-center">Pending Remittance</th>
                    <th class="text-center">Last Remittance</th>
                    <th class="text-center">Last Payment</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="text-center"><a @click="openPopup()">{{summary.Name}}</a></td>
                        <td class="text-center">{{summary.AccountBalance | toCurrency(currencyValues)}}</td>
                        <td class="text-center">    <v-btn 
                                              
                                              @click="openPendingInvoice()"
                                              text 
                                              color="grey darken-1"
                                              width="90"
                                            >
                                              <span class="caption font-weight-light text-capitalize">View</span>
                                              <v-icon small color="grey" right>mdi-open-in-new</v-icon>
                                            </v-btn>
                        </td>
                         <td class="text-center">  <v-btn 
                                                    v-if="summary.LastRemittance !== null"
                                                    @click="downloadInvoice(summary.LastRemittance)"
                                                    text 
                                                    color="grey"
                                                    width="90"
                                                  >
                                                    
                                                    <span v-if="summary.LastRemittance !== null"  class="caption font-weight-light">
                                                      {{summary.LastRemittance.InvoiceGlobalRef}}
                                                      </span>
                                                      <v-icon right>mdi-file-download-outline</v-icon>
                                              
                                                  </v-btn>
                                                  <span v-else>N/A</span>
                        </td>
                        <td class="text-center">{{summary.LastPaymentAmount| toCurrency(currencyValues)}}</td>
                    </tr>
                    
                </tbody>
            </template>
            </v-simple-table>
<!-- MobileTable -->
            <v-simple-table v-else>
            <template>
                <thead>
                </thead>
                <tbody>
                    <tr>
                      <td class="text-center fake-th">Name:</td>
                      <td class="text-center"><a @click="openPopup()">{{summary.Name}}</a></td>
                    </tr>
                    <tr>
                      <td class="text-center fake-th">Balance:</td>
                      <td class="text-center">{{summary.AccountBalance | toCurrency(currencyValues)}}</td>
                    </tr>
                    <tr>
                      <td class="text-center fake-th">Pending Remittance:</td>
                      <td class="text-center">    <v-btn 
                                              
                                              @click="openPendingInvoice()"
                                              text 
                                              color="grey darken-1"
                                              width="90"
                                            >
                                              <span class="caption font-weight-light text-capitalize">View</span>
                                              <v-icon small color="grey" right>mdi-open-in-new</v-icon>
                                            </v-btn>
                        </td>
                    </tr>
                    <tr>
                      <td class="text-center fake-th">Last Remittance:</td>
                      <td class="text-center">  <v-btn 
                                                    v-if="summary.LastRemittance !== null"
                                                    @click="downloadInvoice(summary.LastRemittance)"
                                                    text 
                                                    color="grey"
                                                    width="90"
                                                  >
                                                    
                                                    <span  class="caption font-weight-light">
                                                      {{summary.LastRemittance.InvoiceGlobalRef}}
                                                      </span>
                                                      <v-icon right>mdi-file-download-outline</v-icon>
                                              
                                                  </v-btn>
                        </td>
                    </tr>
                    <tr>
                      <td class="text-center fake-th">Last Payment:</td>
                      <td class="text-center">{{summary.LastPaymentAmount| toCurrency(currencyValues)}}</td>
                    </tr>
                </tbody>
            </template>
            </v-simple-table>
            </div>
        </v-card>
          <Popup
            :dialog="accountPopup"
            @closeDialog="closePopup"
            />
          <PendingInvoice
            :invoiceDialog="pendingInvoice"
            @closeDialog="closePendingInvoice"
            />
    </div>
</template>

<script>
import Popup from '../components/PersonDetailsPopupComponent.vue'
import PendingInvoice from '../components/PendingInvoicePopup.vue'
import LoadingTable from '../components/SkeletonLoaders/SkeletonLoaderTable.vue'
import {mapState} from 'vuex'
import {mapGetters} from 'vuex'

export default {
   components: {
    Popup,
    PendingInvoice,
    LoadingTable
  },
  data() {
    return {
      accountPopup: false,
      pendingInvoice: false,
      windowWidth: window.innerWidth
    }
  },
  computed:{
    ...mapState({
      summary: state => state.Finances.accountSummary
    }),
    ...mapGetters({
      currencyValues : 'currencyInformation',
      summaryLoaded: 'Finances/summaryLoaded'
    }),
  },
  mounted(){
    window.onresize = () => {
                this.windowWidth = window.innerWidth
    }
  },
  methods: {
    openPopup(){
      this.accountPopup = true;
    },
    closePopup() {
      this.accountPopup = false
    },
    openPendingInvoice(){
      this.pendingInvoice = true;
    },
    closePendingInvoice() {
      this.pendingInvoice = false
    },
    downloadInvoice: function(lastRemittance){
      if(lastRemittance !== null){
      const payload =  {
          OID: lastRemittance.OID,
          GlobalRef: lastRemittance.InvoiceGlobalRef
      }
      this.$store.dispatch("Finances/downloadInvoice", payload);
      }
    }
    
  },
    created() {
      this.$store.dispatch("Finances/getAccountSummary");
  },
    filters: {
    toCurrency: function (value, currencyData) {

    if (typeof value !== "number") {

            return value;
        }
        if(currencyData != undefined){
        var formatter = new Intl.NumberFormat(currencyData.LocaleCode, {
            style: 'currency',
            currency: currencyData.CurrencyCode,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });

        return formatter.format(value);
        }
        else{

          return null;
        }
    }
  }
}
</script>
<style scoped>
.fake-th{
      color: rgba(0, 0, 0, 0.6) !important;
      font-weight: bold !important;
}
</style>