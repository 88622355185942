var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"700","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"loading":_vm.loading}},[_c('v-card-title',{staticClass:"primary white--text",attrs:{"color":"secondary"}},[_vm._v(" Uploaded documents for invoice "),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"white"},on:{"click":function($event){return _vm.closeDialog()}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-data-table',{staticClass:"summaryTable",attrs:{"headers":_vm.documentHeaders,"items":_vm.documents,"items-per-page":10},scopedSlots:_vm._u([{key:"item.uploaded",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.Uploaded))+" ")]}},{key:"item.filename",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.Filename)+" ")]}},{key:"item.filesize",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.Filesize)+" ")]}},{key:"item.download",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","color":"grey darken-1","width":"90"},on:{"click":function($event){return _vm.downloadDocument(item.OID, item.Filename)}}},[_c('v-icon',[_vm._v("mdi-download")])],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }