var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"mb-6"},[_c('v-card-title',{staticClass:"secondary white--text pa-3",attrs:{"id":"card-title"}},[_c('div',{},[_c('v-icon',{staticClass:"mr-3",attrs:{"color":"white"}},[_vm._v("mdi-account-supervisor")]),_c('span',{},[_vm._v("Staff Contact Details")])],1)]),(!_vm.staffMembersLoaded)?_c('div',[_c('LoadingTable')],1):_c('v-data-table',{staticClass:"summaryTable",attrs:{"headers":_vm.staffMembersHeaders,"items":_vm.staffMembers,"items-per-page":10,"mobile-breakpoint":768,"search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.Name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.Name)+" ")]}},{key:"item.JobTitle",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.JobTitle)+" ")]}},{key:"item.Landline",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":'tel:' + item.Landline}},[_vm._v(_vm._s(item.Landline))])]}},{key:"item.Mobile",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":'tel:' + item.Mobile}},[_vm._v(_vm._s(item.Mobile))])]}},{key:"item.Email",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":'mailto:' + item.Email}},[_vm._v(_vm._s(item.Email))])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }