<template>

  <v-app>

      <div
        v-if="authState !== 'signedin'"
        class="background-image"
      ></div>
      <div 
        v-if="authState !== 'signedin'"
        class="login-logo cursor-pointer" 
      >
        <v-img
          alt="contractor App logo"
          contain
          src="../src/assets/contractorAppLogoDefault.png"
        />
      </div>
      <div 
        v-if="authState !== 'signedin'"
        class="authenticator"  
      >
        <amplify-authenticator 
          username-alias="email"
        >
          <amplify-sign-up
            header-text=""
            slot="sign-up"
            username-alias="email"
            :form-fields.prop="formFields"
          ></amplify-sign-up>
          <amplify-sign-in
            :form-fields.prop="signInFormFields"
            header-text=""
            slot="sign-in" 
            username-alias="email"
          ></amplify-sign-in>
          <amplify-confirm-sign-up
            header-text="Enter your verification code below"
            slot="confirm-sign-up"
            username-alias="email"
          ></amplify-confirm-sign-up>
        </amplify-authenticator>
        <div class="help-text">

        <p>Need help? <a href="mailto:support@agentos.com">support@agentos.com</a></p>
        <p><strong>Please note when logging in the email address is case sensitive.</strong></p>
        <p>contractorApp by <a href="https://www.agentos.com" target="_blank">agentos.com</a></p>
        </div>
      </div>
      <div v-if="authState === 'signedin' && user"> 
        <router-view></router-view>   
      </div>

  </v-app>

</template>

<script>
// Autologout article:  https://css-tricks.com/detecting-inactive-users/

  window.zESettings = {
    webWidget: {
      color: {
        theme: '#FF69B4',
        launcher: '#CC3A83', // This will also update the badge
        launcherText: '#E589B7',
        button: '#8A0648',
        resultLists: '#691840',
        header: '#203D9D',
        articleLinks: '#FF4500'
      }
    }
  }
import { onAuthUIStateChange } from '@aws-amplify/ui-components'

export default {
  name: 'App',
  components: {
  },
  created() {
    onAuthUIStateChange((authState, authData) => {
      this.authState = authState;
      this.user = authData;
      if(authState !== 'signin') {
        if(this.user) {
          let userDetails = this.user.attributes
          this.$store.commit('SET_USER_DETAILS', userDetails)
          const path = '/accounts'
          if (this.$route.path !== path) this.$router.push(path)
        }
      }
      else {
        this.$store.commit('LOGOUT')
        const path = '/'
        if (this.$route.path !== path) this.$router.push(path)
      }
    })
  },
  mounted() {

  },
  data: () => ({
    user: null,
    authState: null,
    drawer: false,
    formFields: [
      {
        type: 'email',
        // label: 'Email address *',
        // placeholder: 'Enter your email address',
        // required: true,
      },
      {
        type: 'password',
        // label: 'Password *',
        // placeholder: 'Enter a password of your choice',
        // required: true,
      },
    ],
    signInFormFields: [
      {
        type: 'email',
        label: 'Email address *',
        placeholder: 'Enter your email address',
        // inputProps: {autocomplete: 'username', name: 'email'}
        // required: true,
      },
      {
        type: 'password',
        label: 'Password *',
        placeholder: 'Enter your password',
        // inputProps: {autocomplete: 'current-password', name: 'password'}
        // required: true,
      },
    ],
  }),
  beforeDestroy() {
    return onAuthUIStateChange
  }
};
</script>

<style>
    .v-main{
      padding-top:32px !important;
      margin-left:5% !important;
      margin-right: 5% !important;
      
    }
    .v-application{
      background-color: #f5f5f5 !important;
      border-color: #f5f5f5 !important;
    }
  .container {
    padding-top: -30px !important;
  }
.authenticator {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}
.help-text{
    position: absolute;
    bottom: 7%;
    background: white;
    padding: 5px;
    width: 100%;
    border-radius: 5px;
    text-align: center;
}
.help-text a{
    color: #ff9900!important;
}

  /* Transitions ---------------------------------------------------------- */
  .fade-enter {
    opacity: 0;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s ease-out;
  }

  .fade-leave-to {
    opacity: 0;
  }
  .login-logo {
  /* background: white; */
  width: 100%;
  height: 120px;
  display: flex;
  position: absolute;
  top: 2%;
  left: 0;
  z-index: 1;
}


  .slide-fade-enter {
    transform: translateY(50px);
    opacity: 0;
  }

  .slide-fade-enter-active,
  .slide-fade-leave-active {
    transition: all 0.8s ease-out;
  }

  .slide-fade-leave-to {
    opacity: 0;
  }
  .description{
    padding: 15px;
    padding-top: 5px;
    margin-bottom: 0px!important;
  }
  .description{
    padding: 15px;
    padding-top: 10px;
}
.errorMessage{
    background: white;
    color: red;
    padding: 10px;
}
.errorBorder{
    border: 1px solid red;
}
.hidden{
    display: none;
}
.VueCarousel-slide {
  position: relative;
  color: #fff;
  font-family: Arial;
  font-size: 24px;
  text-align: center;
  min-height: 100px;
  visibility: visible;
  flex-basis: 100%!important;
  width: 100%;
}

.label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

  /* Increases font size on all tables on mobile */
   .v-data-table > .v-data-table__wrapper .v-data-table__mobile-table-row td {
    font-size: 1.2rem;
  }
  /* Colours alternative table rows on mobile */
  .v-data-table > .v-data-table__wrapper .v-data-table__mobile-table-row:nth-child(even) td {
    background: #F5F5F5;
  }
    /* All table rows */
  /* tbody tr:nth-of-type(odd) {
   background-color: rgba(242, 102, 66, .03);
  } */


/* Mobile L and < */
/* @media only screen and (max-width: 425px) {
  tbody tr:nth-of-type(odd) td {
   background-color: rgba(242, 102, 66, .05);
  }
} */
.background-image {
  background-image: url("../src/assets/background.jpg");
  background-position: center top;
  background-size: cover;

  height: 100%;
}

</style>
