<template>
    <div>
      <v-snackbar
        
        v-model="snackbar"
        :timeout="timeout"
        :color="snackbarColor" 
      >
        {{snackbarText}}
        <template v-slot:action="{ attrs }">
          <v-btn
            color="white"
            text
            v-bind="attrs"
            @click="snackbar = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
        <v-card :loading="loading" class="mb-6">
            <v-card-title class="secondary white--text pa-3" id="card-title">
            <div class="">
                <v-icon class="mr-3" color="white" >mdi-clipboard-check</v-icon>
                <span class="">Submit a new invoice</span>
            </div>
            </v-card-title>
              <v-form class="pa-6" lazy-validation>

<!-- This cloud search is specific for SALES INSTRUCTIONS -->
  <v-autocomplete
    @update:search-input="isTyping = true" 
    @input="chosenProperties"
    @click:clear="clearSearch"
    v-model="model"
    :search-input.sync="searchQuery"
    label="Search Property"
    :items="searchResult"
    :item-text="itemSubject"
    :item-value="itemValue"
    :loading="loadingAutocomplete"
    :no-data-text="noDataText"
    :menu-props="{ offsetY: true }"
    clearable
    no-filter
    color="black"
    outlined
    hide-no-data
  >
  <template v-slot:item="{ item }">
 {{ item.Subject }}
 </template>
    </v-autocomplete>


      <v-text-field
        :prefix="getCurrencySymbol(currencyValues)"
        v-model="amount"
        outlined
        name="input-7-4"
        placeholder="0.00"
        label="Invoiced Amount"
        color="green"
      ></v-text-field>
      <v-textarea
        v-model="description"
        outlined
        name="input-7-4"
        placeholder="Enter a brief description of the job linked to the invoice if you do not have a job ID. (Invoices linked directly to a job can be sent directly from the maintenance jobs page)."
        label="Job Description"
        color="green"
      ></v-textarea>
  <v-btn  color="success" @click="triggerDocumentUpload()">Upload Invoice Pdf</v-btn >
    <v-file-input
    id="pdf-upload"
    v-show="false"
    truncate-length="15" 
    @change="uploadDocuments" 
    multiple
    ></v-file-input>
    <v-flex class="text-right">
    <v-btn
      class="mr-4"
      @click="submitInvoice()"
    >
      Submit
    </v-btn>
    <v-row v-if="attachedDocuments.length">
                <div>
                  <v-chip
                    v-for="(doc, index) in attachedDocuments"
                    :key="doc.Timestamp"
                    class="ma-2"
                    close
                    color="white"
                    text-color="blue"
                    @click:close="removeDocument(index)"
                  >
                    <v-avatar left>
                      <v-icon>mdi-checkbox-marked-circle</v-icon>
                    </v-avatar>
                    {{ doc.DocumentName }}
                  </v-chip>
                </div>
              </v-row>
    </v-flex>
  </v-form>
        </v-card>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import debounce from 'lodash/debounce'
import { API } from 'aws-amplify'

export default {
  data() {
    return {
      amount: '',
      description: '',
      selectedProperty: '',
      loading: false,
      snackbar: false,
      snackbarColor:"",
      snackbarText:"",
      timeout: 3500,
      attachedDocuments: [],
      model: null,
      searchQuery: null,
      isTyping: false,
      searchResult: [],
      loadingAutocomplete: false,
      hideNoData: true,
      noDataText: ''
    }
  },
  computed:{
    ...mapGetters({
      currencyValues : 'currencyInformation'
    })
  },
  methods : {
    triggerDocumentUpload(){
        document.getElementById('pdf-upload').click();
    },
    getCurrencySymbol (currencyData) {
      if(currencyData !== ''){
        return (0).toLocaleString(
            currencyData.LocaleCode,
            {
            style: 'currency',
            currency: currencyData.CurrencyCode,
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
            }
        ).replace(/\d/g, '').trim()
      }
    },
    submitInvoice(){
      this.loading = true;
      const payload = {
        PropertyID: this.selectedProperty,
        Amount: this.amount,
        Description: this.description,
        Documents : this.attachedDocuments
      }
      this.$store.dispatch("Invoices/submitInvoice", payload)
        .then(() => {
          this.loading = false,
          this.amount = "",
          this.description = "",
          this.attachedDocuments = [],
          this.selectedProperty = "",
          this.snackbar = true,
          this.clearSearch(),
          this.snackbarColor = "green",
          this.snackbarText = "The invoice submitted successfully, please check your submitted invoices."
        })
        .catch(() => {
          this.loading = false
          this.snackbar = true,
          this.snackbarColor = "red"
          this.snackbarText = "There was an error submitting the invoice, please review the form and try again."
        })
    },
    setProperty(property) {
        this.selectedProperty = property
    },
    // Document uploads
    uploadDocuments(files) {
      files.forEach( file => {
        this.createBase64String(file)
      });
      document.getElementById('pdf-upload').value = null;
    },
    createBase64String(fileObject) {
      const reader = new FileReader();

      reader.onload = () => {
        const baser64String = reader.result.split("base64,")[1]
        const documentObject = {
          DocumentName: fileObject.name,
          MimeType: fileObject.type,
          Content: baser64String,
          Size: fileObject.size,
          Timestamp: Date.now()
        }
        this.attachedDocuments.push(documentObject)
      };
      reader.readAsDataURL(fileObject)
    },
     removeDocument(index) {
      this.attachedDocuments.splice(index, 1)
    }, 
    itemValue(item) {
      if(item) {
        return item.OID
      } return ''
    },
    itemSubject(item){
        if(item){
            return item.Subject
        } return ''
    },
    clearSearch() {
      this.isTyping = false
      this.searchQuery = ''
      this.searchResult = []
    },
    chosenProperties() { 
      const selectedProperty = this.model
      this.setProperty(selectedProperty);
    },
    searchProperty(query) {
      API.get('contractorAppMainRest', `/get?query=/${this.$store.state.selectedAccount.CompanyShortName}/company/search/properties/${query}`)
      .then( ({data}) => {
        if(data.Data.length > 0) {
          this.searchResult = data.Data
          this.loading = false
        } 
        else {
            this.hideNoData = false,
            this.loading = false
            this.noDataText = "No data found with this name"
        }
      })
      .catch( () => {
        this.hideNoData = false
        this.loading = false
        this.noDataText = "An error has occured in the system. Try again."
      })
    }
  },
  watch: {
    searchQuery: debounce(function() {
      this.isTyping = false;
    }, 1000),
    isTyping: function(value) {
      if (!value && this.searchQuery && this.searchQuery.length > 5) {
        this.loading = true
        this.searchProperty(this.searchQuery)
      }
    }
  },
}
</script>