<template>
  <v-dialog 
    v-model="dialog"
    max-width="500" 
    persistent
  >
    <v-card :loading="loading">
      <v-card-title class="primary white--text" color="secondary">
        Create Maintenance Job Note
        <v-spacer></v-spacer>
        <v-btn @click="closeDialog()" text color="white">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pt-3">
        <v-row class="mt-3">
          <v-col cols="12">
            <v-textarea
              prepend-inner-icon="mdi-comment-text-outline"
              v-model="msgText"
              outlined
              name="input-7-4"
              placeholder="Enter the note to add against the maintenance job"
              label="Message"
              color="green"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red d-inline" text @click="closeDialog()">Cancel</v-btn>
        <v-btn
          :disabled="!msgText"
          color="green d-inline"
          width="100"
          class="mr-3 white--text"
          @click="sendMessage()"
          >Send</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: [
    'dialog',
    'details',
    'OID'],
  data() {
    return {
      msgText: "",
      loading: false
    }
  },
  methods: {
    closeDialog() {
      this.$emit("closeDialog")
      this.msgText = ""
    },
    sendMessage() {
      this.loading = "primary darken-3"
      const payload = {
        JobID: this.OID,
        Note: this.msgText
      }
      this.$store.dispatch("MaintenanceJobs/submitMaintenanceJobNote", payload)
        .then(() => {
          this.loading = false
          this.msgText = ""
          this.$emit("msgSent", {
            color: "green",
            text: "Note created"
          })
        })
        .catch(() => {
          this.loading = false
          this.msgText = ""
          this.$emit("msgSent", {
            color: "red",
            text: "Error creating note!"
          })
        })
    }
  }
}
</script>
<style></style>