<template>
  <nav v-resize="onResize">
    <v-app-bar
      app
      flat
      dark
      class="nav-bar"
      color="#f26642"
    >
    <v-app-bar-nav-icon @click.stop="drawer = !drawer" class="d-lg-none"></v-app-bar-nav-icon>
    <!-- Invisible button to help center main logo -->
    <!-- <v-btn class="" text disabled rounded></v-btn>  -->
    <v-spacer></v-spacer>
    <!-- AgentPay Logo -->
    <router-link :to="{ name: 'Account'}">
    <v-img 
        src='../assets/contractorAppLogo.png'
        contain
        width="120"
      ></v-img>
    </router-link>
    <v-spacer></v-spacer>
    <!-- <v-btn color="success" @click="toggleTheme">Toggle theme</v-btn> -->
    <!-- Account menu (links to sign-out etc) -->
    <v-menu offset-y>
      <template v-slot:activator="{ on }">
        <v-icon v-on="on">mdi-account</v-icon>
      </template>
      <v-list>
        <v-list-item href="https://agentos.canny.io/agentos-pmapp-requests" target="_blank">
          <v-list-item-title >
            Suggest a feature
          </v-list-item-title>
        </v-list-item>
        <v-list-item href="https://letmc.zendesk.com/hc/en-us/articles/360019320637-Contractor-App" target="_blank">
          <v-list-item-title >
            FAQ
          </v-list-item-title>
        </v-list-item>
        <v-list-item href="mailto:support@agentos.com">
          <v-list-item-title >
            App Support
          </v-list-item-title>
        </v-list-item>
        <v-list-item @click="logout">
          <v-list-item-title >
            <span>Sign Out</span>
            <v-icon color="black" small right>mdi-logout-variant</v-icon>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    </v-app-bar>
  <v-content class="light">
  <v-navigation-drawer
    v-model="drawer"
    app
    dark
    floating
    
    mobile-break-point="1264"
    width="200"
  >
     <v-img
       src="../assets/background.jpg"
       gradient="rgba(27, 27, 27, 0.7), rgba(27, 27, 27, 0.7)"
       height="100%"
     >
     <v-list-item>
       <v-list-item-content>
         <v-list-item-title class="title">
           <div class="d-flex align-center ">
             <!-- <img height="42" width="42" class="mx-1 logo" src="../assets/Logo.png" alt="AgentOS Logo"> -->
             <span class="subtitle-1">{{getCompany}}</span>
           </div>
         </v-list-item-title>
       </v-list-item-content>
     </v-list-item>

     <v-divider></v-divider>

     <v-list
       nav
       :dense="navDrawerDense"
     >
      <v-list-item
        v-for="link in links"
        :key="link.text"
        link
        router :to="link.route"
        active-class="secondary white--text"
      >
        <v-list-item-icon>
          <v-icon>{{ link.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content class="ml-n3">
          <v-list-item-title>{{ link.text }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
     </v-list>
    </v-img>
   </v-navigation-drawer>
   </v-content>
</nav>

</template>

<script>
import {mapGetters} from 'vuex'
import { Auth } from 'aws-amplify';

export default {
  data() {
    return {
      navDrawerDense: true,
      drawer: null,
      links: [
        {icon: 'mdi-hammer-wrench', text: 'Jobs', route:'/jobs'},
        { icon: 'mdi-clipboard-check', text: 'Submit', route:  '/submitinvoice'},
        { icon: 'mdi-receipt', text: 'Invoices', route: '/invoices'},
        { icon: 'mdi-currency-gbp', text: 'Payments', route: '/payments'},
        {icon: 'mdi-card-account-phone', text: 'Contacts', route: '/contacts'},
        {icon: 'mdi-account-group', text: 'Accounts', route: '/accounts'}
      ]
    }
  }
  ,
  methods: {
    logout() {
      try {
       Auth.signOut();
    } catch (error) {
        // Do nothing
    }
    this.$store.commit('LOGOUT');
    this.$router.push('/')
    },
    reroute(route) {
      this.$router.push(route)
    },
    toggleTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
    },
    onResize () {
      if(window.innerWidth <= 768) {
        this.navDrawerDense = true
      } else this.navDrawerDense = false
    },
  },
  computed: {
    ...mapGetters(['returnUserFirstName',
    'getCompany'])
  },
  mounted () {
    this.onResize()
  },
}
</script>
<style scoped>

.logo {
  filter: brightness(90%);
  border-radius: 10px;
}
</style>
