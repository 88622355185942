import Vue from 'vue'
import Vuex from 'vuex'


import Finances from './modules/Finances/Finances'
import MaintenanceJobs from './modules/MaintenanceJobs/MaintenanceJobs'
import Contacts from './modules/Contacts/Contacts'
import Invoices from './modules/Invoices/Invoices'
import { API, graphqlOperation  } from 'aws-amplify'
import { createContractorAccount } from '../graphql/mutations'
import { deleteContractorAccount } from '../graphql/mutations'
import { listContractorAccounts } from '../graphql/queries'
import { listContractorAccountSyncDateTimes } from '../graphql/queries'
import { createContractorAccountSyncDateTime } from '../graphql/mutations'
import { updateContractorAccountSyncDateTime } from '../graphql/mutations'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    noAccounts: '',
    loginToken: '',
    errorMessages: [],
    currencyData : '',
    userDetails: '',
    userAccounts: '',
    selectedAccount: '',
    accountsLoading: '',
    loginFailed: false,
    contractorsToSave: '',
    latestContractors: [],
    graphQLContractors:[],
    lastAccountSyncDate:''
  },
  mutations: {
    NO_ACCOUNTS(state, data){
      state.noAccounts = data;
    },
    SET_USER_DETAILS(state, data){
      state.userDetails = data
    },
    LOGIN_FAILED(state){
      state.loginFailed = true
    },
    LOGIN_PENDING(state){
      state.loginFailed = false
    },
    SET_ACCOUNT_LOADING_STATUS(state, data)
    {
      state.accountsLoading = data;
    },
    SET_ERROR(state, data) {
      state.errorMessages.push(data)
    },
    SET_CURRENCY_DATA(state,data){
      localStorage.setItem('currencyData', JSON.stringify(data))
      state.currencyData = data;
    },
    LOGOUT(state) {
      state.loginToken = '',
      state.errorMessages = [],
      state.currencyData = '',
      state.clientName = '',
      state.companyName = '',
      state.userDetails = ''
    },
    SET_USER_ACCOUNTS(state, data){
      localStorage.setItem('userAccounts', JSON.stringify(data))
      state.userAccounts = data;
    },
    SET_SELECTED_ACCOUNT(state, data){
      localStorage.setItem('selectedAccount', JSON.stringify(data))
      state.selectedAccount = data;
    },
    SET_CONTRACTORS_TO_SAVE(state, data){
      state.contractorsToSave = data;
    },
    SET_LATEST_CONTRACTORS(state, data){
      state.latestContractors = data;
    },
    SET_GRAPHQL_CONTRACTORS(state, data){
      state.graphQLContractors = data;
    },
    SET_LAST_SYNC_DATE(state, date){
      state.lastAccountSyncDate = date;
    }
  },
  actions: {
    // LOGIN / AUTHENTICATION + SESSION-STATUS & BRANCH SELECTOR
    // ------------------------------------------------------------------------
    // ------------------------------------------------------------------------
    getUserAccounts({commit, dispatch}){
      commit('SET_ACCOUNT_LOADING_STATUS', true);
      commit('LOGIN_PENDING')
      commit('NO_ACCOUNTS', false)
      dispatch('accountSync')
        .then( () => {
        }).catch(() => commit('LOGIN_FAILED'))
    },
    getCurrencyData({state, commit}) {
      API.get('contractorAppMainRest', `/get?query=/${state.selectedAccount.CompanyShortName}/company/currency`)
      .then(res => {
        commit('SET_CURRENCY_DATA', res.data)
      })
    },
    accountSync({dispatch}){
      const todaysDate = new Date()
      const todaysDateISO = todaysDate.toISOString()
      API.graphql(graphqlOperation(listContractorAccountSyncDateTimes)) // check if DB table has any entries
        .then(res => {
          if(res.data.listContractorAccountSyncDateTimes.items.length) { // if one exists, update it...
            const savedDateTime = res.data.listContractorAccountSyncDateTimes.items[0] // 1st and only object in array
            this.commit("SET_LAST_SYNC_DATE", new Date(savedDateTime.lastUpdate).toISOString())
            const dateToCheck = new Date();
            dateToCheck.setDate(dateToCheck.getDate() - 7);
            if(new Date(savedDateTime.lastUpdate) < dateToCheck){
              dispatch('syncAccounts')
              const payload = {
                id: savedDateTime.id,
                lastUpdate: todaysDateISO
              }
              API.graphql(graphqlOperation(updateContractorAccountSyncDateTime, {input: payload}))
              this.commit("SET_LAST_SYNC_DATE", todaysDateISO)
            }
            else{   
                dispatch('getGraphQlAccounts')
            }
        }
        else{
          dispatch('syncAccounts', true)
          const payload = {
            lastUpdate: todaysDateISO
          }
          API.graphql(graphqlOperation(createContractorAccountSyncDateTime, {input: payload})).then(() => {
            this.commit("SET_LAST_SYNC_DATE", todaysDateISO)
          });
        }
      })
    },
    resynchroniseAccounts({dispatch, commit}){
      commit('SET_ACCOUNT_LOADING_STATUS', true);
      const todaysDate = new Date()
      const todaysDateISO = todaysDate.toISOString()
      dispatch('syncAccounts')
      const payload = {
        lastUpdate: todaysDateISO
      }
      API.graphql(graphqlOperation(createContractorAccountSyncDateTime, {input: payload})).then(() => {
        this.commit("SET_LAST_SYNC_DATE", todaysDateISO)
      });
    },
    getGraphQlAccounts({commit}){
      API.graphql(graphqlOperation(listContractorAccounts, {limit: 1000}))
        .then(res => {
            commit('SET_USER_ACCOUNTS', res.data.listContractorAccounts.items);
            commit('SET_ACCOUNT_LOADING_STATUS', false);
        })
    },
    syncAccounts({dispatch, state, commit}, firstTimeLogin){
      API.get('contractorAppMainRest', '/people')
      .then( res => {
        if(res.status === '200') {
          commit('SET_LATEST_CONTRACTORS', res.people.Data) // response from lambda ok
          if(res.people.Data.length) { // user has personAccounts with AgentOS
            if(firstTimeLogin) {

              commit("SET_CONTRACTORS_TO_SAVE", state.latestContractors);
              dispatch('saveNewContractors').then(() => dispatch('getGraphQlAccounts'))
               // accounts are saved in DB, now call Dynamo DB to render data
            } else { 
              API.graphql(graphqlOperation(listContractorAccounts, {limit: 1000})).then(res => {
                commit('SET_GRAPHQL_CONTRACTORS', res.data.listContractorAccounts.items)
              }).then(() => {
                dispatch('updateSavedAccounts')
              })
              
            }
          }
          else{
            commit('SET_ACCOUNT_LOADING_STATUS', false);
            commit('NO_ACCOUNTS', true)
          }
        } else {
          console.log('Error: ', res)
        }
      }).catch(() => {
        commit("LOGIN_FAILED");
      })
    },
   
    updateSavedAccounts({dispatch, state}) { // This method might very well be buggy...
          const savedAccounts = state.graphQLContractors
          const latestAccounts = state.latestContractors // existing accounts in DB
          // Remove accounts for reinstating the active ones
          savedAccounts.forEach(savedAccount => { // compare each account by id
              // savedAccount not present among latestAccounts
              const personAccount = {
                id: savedAccount.id
              }
              API.graphql(graphqlOperation(deleteContractorAccount, {input: personAccount})).then(() => {
                console.log("Account cleaned");
              }) // delete savedAccount
          })
          // Reinstate active accounts
          latestAccounts.forEach(latestAccount => {            
              let createDate = new Date().toISOString();
              if(latestAccount.CreatedDate !== null || latestAccount.CreatedDate !== undefined)
                createDate = `${latestAccount.CreatedDate}Z`
              const personAccount = {
                CompanyName: latestAccount.CompanyName,
                CreatedDate: createDate,
                FirstName: latestAccount.Firstname,
                PersonOID: latestAccount.PersonOID,
                Surname: latestAccount.Surname,
                ContractorCompany: latestAccount.ContractorCompany,
                CompanyShortName: latestAccount.CompanyShortName,
                GlobalReference: latestAccount.GlobalReference,
                ManagingBranch: latestAccount.ManagingBranch
              }
              API.graphql(graphqlOperation(createContractorAccount, {input: personAccount})).then(() => {
                dispatch('getGraphQlAccounts')
              })
          })
      
    },
    saveNewContractors({state}){
      
      state.contractorsToSave.forEach(person => {
        let createDate = new Date().toISOString();
              if(person.CreatedDate !== null || person.CreatedDate !== undefined){
                createDate = `${person.CreatedDate}Z`
              }
        const personAccount = {
          CompanyName: person.CompanyName,
          CreatedDate: createDate,
          FirstName: person.Firstname,
          PersonOID: person.PersonOID,
          Surname: person.Surname,
          ContractorCompany: person.ContractorCompany,
          CompanyShortName: person.CompanyShortName,
          GlobalReference: person.GlobalReference,
          ManagingBranch: person.ManagingBranch
        }
        return API.graphql(graphqlOperation(createContractorAccount, {input: personAccount}))
          .then( () => console.log('PersonAccount saved.'))
          .catch( (err) => console.log('Error in savePersonAccount method.', err))
      })
    },
  },

  methods: {

    },
  getters: {
    loggedIn(state) {
      return !!state.loginToken
    },
    accountLoadingStatus(state){
      return state.accountsLoading;
    },
    returnUserFirstName(state) {
      if(state.selectedAccount === '')
        return undefined
      else
        return `${state.selectedAccount.CompanyName} (Account: ${state.selectedAccount.GlobalReference})`
    },
    currencyInformation: state => {
      return state.currencyData;
    },
    getCompany(state){
      return state.selectedAccount.CompanyName
    },
    userAccounts(state){
      return state.userAccounts
    },
    hasLoginFailed(state){
      return state.loginFailed
    },
    noAccounts(state){
      return state.noAccounts
    }
      
  }, 
  modules: {
    Finances,
    MaintenanceJobs,
    Contacts,
    Invoices
  },

})
