<template>
<div>
      <BranchContactsComponent />
      <StaffContactsComponent />
    </div>
</template>
<script>
import BranchContactsComponent from '../components/BranchContactsComponent.vue'
import StaffContactsComponent from '../components/StaffContactsComponent.vue'

export default {
  components: {
    BranchContactsComponent,
    StaffContactsComponent
  },
}
</script>