<template>
  <v-dialog 
    v-model="dialog"
    max-width="500" 
    persistent
  >
    <v-card :loading="loading">
      <v-card-title class="primary white--text" color="secondary">
        Submit Invoice for maintenance {{jobNumber}}
        <v-spacer></v-spacer>
        <v-btn @click="closeDialog()" text color="white">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pt-3">
        <v-row>
          <v-col>
            
            <v-text-field
              :prefix="getCurrencySymbol(currencyValues)"
              v-model="amount"
              outlined
              name="input-7-4"
              placeholder="0.00"
              label="Invoiced Amount"
              color="green"
            ></v-text-field>
          </v-col>
        </v-row>
                <v-row>
          <v-col>
        <v-textarea
        v-model="description"
        outlined
        name="input-7-4"
        placeholder="Additional information for the invoice to submit."
        label="Job Description"
        color="green"
      ></v-textarea>
      </v-col>
    </v-row>
      <v-row>
    <v-col>
  <v-btn  color="success" @click="triggerDocumentUpload()">Upload Invoice Pdf</v-btn >
    <v-file-input
    id="pdf-upload"
    v-show="false"
    truncate-length="15" 
    @change="uploadDocuments" 
    multiple
    ></v-file-input>
    </v-col>
      </v-row>
    <v-row v-if="attachedDocuments.length">
                <div>
                  <v-chip
                    v-for="(doc, index) in attachedDocuments"
                    :key="doc.Timestamp"
                    class="ma-2"
                    close
                    color="white"
                    text-color="blue"
                    @click:close="removeDocument(index)"
                  >
                    <v-avatar left>
                      <v-icon>mdi-checkbox-marked-circle</v-icon>
                    </v-avatar>
                    {{ doc.DocumentName }}
                  </v-chip>
                </div>
        </v-row>


      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red d-inline" text @click="closeDialog()">Cancel</v-btn>
        <v-btn
          :disabled="!amount"
          color="green d-inline"
          width="100"
          class="mr-3 white--text"
          @click="sendInvoice()"
          >Send</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import {mapGetters} from 'vuex'

export default {
  props: [
    'dialog',
    'OID',
    'jobNumber'],
  data() {
    return {
      amount: "",
      description: "",
      attachedDocuments: [],
      loading: false
    }
  },
  computed:{
    ...mapGetters({
      currencyValues : 'currencyInformation'
    })
  },
  methods: {
    closeDialog() {
      this.$emit("closeDialog")
      this.amount = ""
    },
    sendInvoice() {
      this.loading = "primary darken-3"
      const payload = {
        MaitenanceJobID: this.OID,
        Amount: this.amount,
        Description: this.description,
        Documents: this.attachedDocuments
      }
      this.$store.dispatch("Invoices/submitInvoiceFromJob", payload)
        .then(() => {
          this.loading = false
          this.amount = ""
          this.attachedDocuments = [],
          this.description = []
          this.$emit("invoiceSent", {
            color: "green",
            text: "Invoice submitted successfully"
          })
        })
        .catch(() => {
          this.loading = false
          this.$emit("invoiceSent", {
            color: "red",
            text: "There was an issue submitting the invoice!"
          })
        })
    },
    getCurrencySymbol (currencyData) {
      if(currencyData !== ''){
        return (0).toLocaleString(
            currencyData.LocaleCode,
            {
            style: 'currency',
            currency: currencyData.CurrencyCode,
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
            }
        ).replace(/\d/g, '').trim()
      }
    },
    // Document uploads
    uploadDocuments(files) {
      files.forEach( file => {
        this.createBase64String(file)
      });
      document.getElementById('pdf-upload').value = null;
    },
    createBase64String(fileObject) {
      const reader = new FileReader();

      reader.onload = () => {
        const baser64String = reader.result.split("base64,")[1]
        const documentObject = {
          DocumentName: fileObject.name,
          MimeType: fileObject.type,
          Content: baser64String,
          Size: fileObject.size,
          Timestamp: Date.now()
        }
        this.attachedDocuments.push(documentObject)
      };
      reader.readAsDataURL(fileObject)
    },
     removeDocument(index) {
      this.attachedDocuments.splice(index, 1)
    }, 
        triggerDocumentUpload(){
        document.getElementById('pdf-upload').click();
    },
  }
}
</script>
<style></style>