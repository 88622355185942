<template>
<div>
      <MaintenanceJobsTableComponent />
    </div>
</template>
<script>
import MaintenanceJobsTableComponent from '../components/MaintenanceJobTableComponent.vue'

export default {
  components: {
    MaintenanceJobsTableComponent
  },
}
</script>