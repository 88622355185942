var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"mb-6"},[_c('v-card-title',{staticClass:"secondary white--text pa-3",attrs:{"id":"card-title"}},[_c('div',{},[_c('v-icon',{staticClass:"mr-3",attrs:{"color":"white"}},[_vm._v("mdi-receipt")]),_c('span',{},[_vm._v("Submitted Invoices")])],1)]),(!_vm.invoicesLoaded)?_c('div',[_c('LoadingTable')],1):_c('v-data-table',{staticClass:"summaryTable",attrs:{"headers":_vm.invoiceHeaders,"items":_vm.invoices,"items-per-page":10,"mobile-breakpoint":768,"search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.Created",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.Created))+" ")]}},{key:"item.JobNumber",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.JobNumber)+" ")]}},{key:"item.Address",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.Address)+" ")]}},{key:"item.Amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toCurrency")(item.Amount,_vm.currencyValues))+" ")]}},{key:"item.State",fn:function(ref){
var item = ref.item;
return [_c('span',{class:_vm.getStatusColourCoding(item.State)},[_vm._v(_vm._s(item.State)+" ")])]}},{key:"item.documents",fn:function(ref){
var item = ref.item;
return [(_vm.showDocuments(item.Documents))?_c('v-btn',{attrs:{"text":"","color":"grey darken-1","width":"90"},on:{"click":function($event){return _vm.openDocumentPopup(item.Documents)}}},[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-file-outline")])],1):_c('v-btn',{attrs:{"text":"","width":"90"}},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-file-excel-outline")])],1)]}}],null,true)}),_c('p',{staticClass:"description"},[_vm._v("*Authorised and Rejected invoices will only be retained for 90 days from the date they were submitted. Submitted invoices will be retained longer if no action has been taken on them within the 90 day period.")])],1),_c('DocumentPopup',{attrs:{"dialog":_vm.documentDialog,"documents":_vm.selectedInvoiceDocuments},on:{"closeDialog":_vm.closeDocumentPopup}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }