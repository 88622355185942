import { API } from 'aws-amplify'
import axios from 'axios'

export default {
  namespaced: true,
  state: {
    submittedInvoices: '',
    invoicesLoadedState : false
  },
  mutations: {
    SET_SUBMITTED_INVOICES(state, data) {
      state.submittedInvoices = data
    },
    SET_INVOICES_LOADED_STATE(state, data) {
      state.invoicesLoadedState = data
    }
  },
	actions :  {

    getSubmittedInvoices({commit, rootState}){
      commit('SET_INVOICES_LOADED_STATE', false);
      API.get('contractorAppMainRest', `/get?query=/${rootState.selectedAccount.CompanyShortName}/invoices/${rootState.selectedAccount.PersonOID}/submitted`)
      .then(res => {
        commit('SET_SUBMITTED_INVOICES', res.data)
        commit('SET_INVOICES_LOADED_STATE', true);
      })
      },
      submitInvoiceFromJob({rootState, dispatch}, payload){
        const postParams = {
          body: {
            MaitenanceJobID: payload.MaitenanceJobID,
            Amount: payload.Amount,
            Description: payload.Description
          }
        }
        API.post('contractorAppMainRest', `/post?query=/${rootState.selectedAccount.CompanyShortName}/invoices/${rootState.selectedAccount.PersonOID}/submit`, postParams)
        .then(res =>{
          if(payload.Documents.length > 0){
            payload.Documents.forEach(document => {
              const documentPayload = {
                InvoiceID: res.data.OID,
                SubmissionDocument: document
              }
              dispatch("attachDocumentToInvoice", documentPayload)
            })
          }
          dispatch("getSubmittedInvoices")
          }
          )
      },
      submitInvoice({rootState, dispatch}, payload){
        const postParams = {
          body: {
            PropertyID: payload.PropertyID,
            Amount: payload.Amount,
            Description: payload.Description,
          }
        }
        API.post('contractorAppMainRest', `/post?query=/${rootState.selectedAccount.CompanyShortName}/invoices/${rootState.selectedAccount.PersonOID}/submit`, postParams)
          .then(res =>{
            if(payload.Documents.length > 0){
              payload.Documents.forEach(document => {
                const documentPayload = {
                  InvoiceID: res.data.OID,
                  SubmissionDocument: document
                }
                dispatch("attachDocumentToInvoice", documentPayload)
              })
            }
            dispatch("getSubmittedInvoices")
          }
          )
      },
      downloadDocument({rootState}, payload){
        API.get('contractorAppMainRest', `/get?query=/${rootState.selectedAccount.CompanyShortName}/download/${rootState.selectedAccount.PersonOID}/document/${payload.OID}`)
        .then(res => {
            const byteCharacters = atob(res.data.Content);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);

          const blob = new Blob([byteArray], {type: res.data.ContentType});


          var fileURL = window.URL.createObjectURL(blob);
          var fileLink = document.createElement('a');
          fileLink.href = fileURL;
          fileLink.setAttribute('download', `${payload.Filename}.pdf`);
          document.body.appendChild(fileLink);

          fileLink.click();
        })
    },
    attachDocumentToInvoice({rootState}, payload) {
      return axios({
        method: 'POST',
        url: `https://live-api.letmc.com/corporate/uploads/letmcletting/${rootState.selectedAccount.CompanyShortName}/maintenance/submittedinvoices/${payload.InvoiceID}`,
        data: payload.SubmissionDocument,
        headers: {
         api_key: "gODFejV2beuCNdbBq-k6cKViS8LmmU_7KdY7fnbOPOM1"
        },
      });
    }
  },
  getters:{
      submittedInvoices: state => {
        return state.submittedInvoices.Data;
      },
      invoicesLoaded: state => {
        return state.invoicesLoadedState;
      }
  }
}
