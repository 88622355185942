<template>
  <v-dialog
      v-model="dialog"
      max-width="600"
    >
      <v-card>
        <v-card-title class="title primary white--text font-weight-light px-4">
          {{ summary.Name }}
          <v-spacer></v-spacer>
          <v-btn @click="closeDialog()" text color="white">
            <v-icon right>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pt-3 px-1">
          <p><strong>BankAccount:</strong> {{summary.AccountNumber}}</p>
          <p><strong>Email:</strong> {{summary.Email}}</p>
        </v-card-text>
      </v-card>
    </v-dialog>
</template>

<script>
import {mapState} from 'vuex'

export default {
  props: [
    'dialog'
  ],
  data() {
    return {
    }
  },
  methods: {
    closeDialog() {
      this.$emit('closeDialog');
    }
  },
  computed:{
    ...mapState({
      summary: state => state.Finances.accountSummary
    })
  },
}
</script>