import Vue from 'vue'
import VueRouter from 'vue-router'
import Master from '../views/Master.vue'
import Home from '../views/Home.vue'
import Payments from '../views/Summary.vue'
import SubmitInvoice from '../views/SubmitInvoice.vue'
import Invoices from '../views/Invoices.vue'
import Contacts from '../views/Contacts.vue'
import Jobs from '../views/Jobs.vue'

Vue.use(VueRouter)
  const routes = [
  {
    path: '/contractorapp',
    redirect: '/',
    name: 'contractorapp',
    component: Master,
    children: [
      {
        path: '/accounts',
        name: 'Home',
        component: Home
    },
      {
        path: '/payments',
        name: 'Payments',
        component: Payments
      },
      {
        path: '/submitinvoice',
        name: "SubmitInvoice",
        component: SubmitInvoice
      },
      {
        path: '/invoices',
        name: "Invoices",
        component: Invoices
      },
      {
        path: '/contacts',
        name: "Contacts",
        component: Contacts
      },
      {
        path: '/jobs',
        name: "Jobs",
        component: Jobs
      },
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
