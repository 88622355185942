<template>
  <v-dialog 
    v-model="dialog"
    max-width="700" 
    persistent
  >
    <v-card :loading="loading">
      <v-card-title class="primary white--text" color="secondary">
        Uploaded documents for invoice
        <v-spacer></v-spacer>
        <v-btn @click="closeDialog()" text color="white">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-data-table class="summaryTable"
                :headers="documentHeaders"
                :items="documents"
                :items-per-page="10"
            >  
      <!-- Reported At Column -->
      <template v-slot:[`item.uploaded`]="{ item }">
        {{item.Uploaded | formatDate()}}
      </template>
      <!-- Reference Column -->
      <template v-slot:[`item.filename`]="{ item }">
        {{item.Filename}}
      </template>
          <!-- Address Column -->
      <template v-slot:[`item.filesize`]="{ item }">
        {{item.Filesize}}
      </template>
        <!-- Priority Column -->
      <template v-slot:[`item.download`]="{ item }">
                <v-btn                                 
                            text 
                            color="grey darken-1"
                            width="90"
                            @click="downloadDocument(item.OID, item.Filename)"
                          >
                            <v-icon>mdi-download</v-icon>
            </v-btn>
      </template>
        </v-data-table>

    </v-card>
  </v-dialog>
</template>
<script>
import moment from 'moment'

export default {
    props: [
        'dialog',
        'documents',
    ],
  data() {
    return {
        documentHeaders: [
          { text: 'Uploaded At', value:'uploaded', align:"center" },
          { text: 'Filename', value: 'filename', align:"center" },
          { text: 'File Size', value: 'filesize', align:"center" },
          { text: 'Download', value: 'download', align:"center" },
        ],
        loading:false
    }
  },
  methods:{
    closeDialog() {
      this.$emit("closeDialog");
    },
    downloadDocument: function(documentID, documentName){
      const payload =  {
          OID: documentID,
          Filename: documentName 
      }
      this.$store.dispatch("Invoices/downloadDocument", payload);
    }
  },
    filters: {
    formatDate: function(value){
      return moment(String(value)).format('DD/MM/YYYY');
    }
  }
}
</script>