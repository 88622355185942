<template>
    <div>
        <!-- Snackbar indicating API request responses -->
      <v-snackbar
        
        v-model="snackbar"
        :timeout="timeout"
        :color="snackbarColor" 
      >
        {{snackbarText}}
        <template v-slot:action="{ attrs }">
          <v-btn
            color="white"
            text
            v-bind="attrs"
            @click="snackbar = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
        <v-card class="mb-6">
            <v-card-title class="secondary white--text pa-3" id="card-title">
            <div class="">
                <v-icon class="mr-3" color="white" >mdi-hammer-wrench</v-icon>
                <span class="">Maintenance Jobs</span>
            </div>
            </v-card-title>
            <div v-if="!maintenanceJobsLoaded">
            <LoadingTable />
            </div>
            <v-data-table v-else class="summaryTable"
                :headers="maintenanceHeaders"
                :items="maintenanceJobs"
                :items-per-page="10"
                :mobile-breakpoint="962"
                  sort-by="ReportedAt"
                sort-desc
                :search="search"
            >
        <template v-slot:top>
        <v-text-field
          v-model="search"
          label="Search"
          class="mx-4"
        ></v-text-field>
      </template>
      <!-- Reported At Column -->
      <template v-slot:[`item.ReportedAt`]="{ item }">
        {{item.ReportedAt | formatDate() }}
      </template>
      <!-- Reference Column -->
      <template v-slot:[`item.JobID`]="{ item }">
        {{item.JobID}}
      </template>
          <!-- Address Column -->
      <template v-slot:[`item.Address`]="{ item }">
        {{item.Address}}
      </template>
        <!-- Priority Column -->
      <template v-slot:[`item.Priority`]="{ item }">
        {{item.Priority}}
      </template>
      <!-- Description Column -->
      <template v-slot:[`item.description`]="{ item }">
        {{item.Description}}
      </template>
      <!-- Photos Column -->
      <template v-slot:[`item.photos`]="{ item }">
        <v-btn              v-if="item.Photos.length > 0"                                 
                            @click="openPopup(item.Photos)"
                            text 
                            color="grey darken-1"
                            width="90"
                          >
                            <v-icon color="green">mdi-camera</v-icon>
            </v-btn>
            <div v-else>
                  <v-tooltip open-delay="500" bottom>
                    <template v-slot:activator="{ on }">
                    <v-icon v-on="on" color="red">mdi-camera-off</v-icon>
                    </template>
                    <span>No photos uploaded for job</span>
                  </v-tooltip>
            </div>
            
      </template>
            <!-- Status Column -->
            <template v-slot:[`item.IsCompletedInApp`]="{ item }">
                            <v-btn v-if="item.IsCompletedInApp"
                                  @click="markJobIncomplete(item.OID)"
                                  text 
                                  color="green"
                                  width="90"
                                  >
                                  <span  class="font-weight-light">
                                      Completed
                                      </span>
                              </v-btn>
                              <v-btn v-else
                                  @click="markJobComplete(item.OID)"
                                  text 
                                  color="red"
                                  width="90"
                                  >
                                  <span  class="font-weight-light">
                                      Pending
                                      </span>
                              </v-btn>
            </template>


           <!-- Job Sheet Column -->
          <template v-slot:[`item.actions`]="{ item }">
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      icon
                      outlined
                    >
                    <v-icon small>mdi-file-tree</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item>
                      <v-btn @click="downloadJobSheet(item.OID, item.JobID)" outlined  text color="grey menu-button">
                                <v-icon small>mdi-clipboard-list-outline</v-icon> Job Sheet
                      </v-btn>
                    </v-list-item>
                    <v-list-item>
                            <v-tooltip open-delay="2500" bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn @click="openNotePopup(item.OID)" outlined text v-on="on" color="grey">
                            <v-icon small>mdi-comment-text-outline</v-icon> Add Note
                          </v-btn>
                        </template>
                        <span>Add a note for this maintenance job</span>
                      </v-tooltip>
                    </v-list-item>
                    <v-list-item>
                      <v-tooltip open-delay="2500" bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn @click="openInvoicePopup(item.OID, item.JobID)" outlined text v-on="on" color="grey">
                            <v-icon small>mdi-clipboard-check</v-icon> Submit Invoice
                          </v-btn>
                        </template>
                        <span>Submit a new invoice for this maintenance job</span>
                      </v-tooltip>
                    </v-list-item>
                  </v-list>
                </v-menu>
          </template>





                      <!-- Job Sheet Column -->
          <template v-slot:[`item.jobsheet`]="{ item }">
              <v-btn @click="downloadJobSheet(item.OID, item.JobID)" outlined small text color="grey">
                <v-icon small>mdi-clipboard-list-outline</v-icon>
              </v-btn>
      </template>
            <template v-slot:[`item.note`]="{ item }">
              <v-tooltip open-delay="2500" bottom>
            <template v-slot:activator="{ on }">
              <v-btn @click="openNotePopup(item.OID)" outlined small text v-on="on" color="grey">
                <v-icon small>mdi-comment-text-outline</v-icon>
              </v-btn>
            </template>
            <span>Add a note for this maintenance job</span>
          </v-tooltip>
          </template>
          <template v-slot:[`item.invoice`]="{ item }">
            <v-tooltip open-delay="2500" bottom>
            <template v-slot:activator="{ on }">
              <v-btn @click="openInvoicePopup(item.OID, item.JobID)" outlined small text v-on="on" color="grey">
                <v-icon small>mdi-clipboard-check</v-icon>
              </v-btn>
            </template>
            <span>Submit a new invoice for this maintenance job</span>
          </v-tooltip>
          </template>
            </v-data-table>
                <p class="description">*Only displays maintenance jobs reported in the past 90 days</p>
      <!-- Snackbar indicating API request responses -->
        </v-card>
        <ImageCarouselPopup
            :dialog="dialog"
            :photos="photos"
            @closeDialog="closePopup"
        />
        <SubmitNotePopup
          :dialog="submitNotePopup"
          :OID="maintenanceJobID"
          @closeDialog="closeNotePopup"
          @msgSent="msgSent"
        />
        <SubmitInvoicePopup
        :dialog="newInvoicePopup"
        :OID="maintenanceJobID"
        :jobNumber="jobNumber"
        @closeDialog="closeInvoicePopup"
        @invoiceSent="invoiceSent"
        />
    </div>
</template>

<script>
import moment from 'moment'
import {mapGetters} from 'vuex'
import ImageCarouselPopup from '../components/ImageCarouselPopup.vue'
import SubmitNotePopup from '../components/SubmitNotePopup.vue'
import SubmitInvoicePopup from '../components/SubmitInvoicePopupComponent.vue'
import LoadingTable from '../components/SkeletonLoaders/SkeletonLoaderTable.vue'

export default {
    components: {
        ImageCarouselPopup,
        SubmitNotePopup,
        SubmitInvoicePopup,
        LoadingTable
    },
  data() {
    return {
        maintenanceHeaders: [
          { text: 'Reported', value:'ReportedAt', class:"maintenanceCell", align:"center", width:"12%", sortable:true } ,
          { text: 'Reference', value: 'JobID', class:"maintenanceCell", align:"center", width:"12%", sortable:true},
          { text: 'Address', value: 'Address', class:"maintenanceCell", align:"center", width:"12%", sortable:true },
          { text: 'Priority', value: 'Priority', class:"maintenanceCell", align:"center", width:"10%", sortable:true, sort: (a, b) => this.itemValues(b) - this.itemValues(a)},
          { text: 'Description', value: 'description', class:"maintenanceCell", align:"center", width:"28%", sortable:false },
          { text: 'Photos', value: 'photos', class:"maintenanceCell", align:"center", width:"5%", sortable:false},
          { text: 'Status', value: 'IsCompletedInApp', class:"maintenanceCell", align:"center", width:"12%", sortable:true},
          {text: 'Actions', value: 'actions', class:"maintenanceCell", align:"center", width:"5%", sortable:false}
        ],
        photos: '',
        dialog: false,
        submitNotePopup: false,
        maintenanceJobID: String,
        snackbar: false,
        snackbarColor:"",
        snackbarText:"",
        timeout: 3500,
        newInvoicePopup: false,
        jobNumber: "",
        search: ''
    }
  },
  computed: {
    ...mapGetters({
      maintenanceJobs : 'MaintenanceJobs/maintenanceJobs',
      currencyValues : 'currencyInformation',
      maintenanceJobsLoaded : 'MaintenanceJobs/maintenanceJobsLoadedState'
    }),
  },
  methods: {
    itemValues(item){
      /// Get Values for priority
      if(item ==="High"){
        return 3
      }
      if(item === "Medium"){
        return 2
      }
      else if( item === "Low"){
        return 1
      }
    },
    downloadJobSheet(jobID, jobReference){
      const payload =  {
          JobID: jobID,
          Filename: "JobSheet-" + jobReference + ".pdf"
      }
      this.$store.dispatch("MaintenanceJobs/downloadJobsheet", payload);
    },
    openPopup(value){
      this.dialog = false;
      this.submitNotePopup = false;
      this.maintenanceJobID = '';
      this.newInvoicePopup = false;
      this.photos = false;
      this.photos = value;
      this.dialog = true;
    },
    closePopup() {
     this.photos = '';
      this.dialog = false
    },
    openNotePopup(value){
      this.dialog = false;
      this.submitNotePopup = false;
      this.maintenanceJobID = '';
      this.newInvoicePopup = false;
      this.photos = false;
      this.maintenanceJobID = value;
      this.submitNotePopup = true;
    },
    closeNotePopup() {
      this.maintenanceJobID = '';
      this.submitNotePopup = false;
    },
    openInvoicePopup(jobId, jobNumber){
      this.dialog = false;
      this.submitNotePopup = false;
      this.maintenanceJobID = '';
      this.newInvoicePopup = false;
      this.photos = false;
      this.maintenanceJobID = jobId;
      this.jobNumber = jobNumber;
      this.newInvoicePopup = true;
    },
    closeInvoicePopup(){
      this.maintenanceJobID = "";
      this.jobNumber = "";
      this.newInvoicePopup = false;
    },
    invoiceSent(payload) {
      this.newInvoicePopup = false
      this.snackbar = true,
      this.snackbarColor = payload.color
      this.snackbarText = payload.text
    },
    markJobComplete(jobID){
        const payload =  {
          jobID: jobID
      }
      this.$store.dispatch("MaintenanceJobs/markJobComplete", payload);
    },
    markJobIncomplete(jobID){
        const payload =  {
          jobID: jobID
      }
      this.$store.dispatch("MaintenanceJobs/markJobIncomplete", payload);
    },
    msgSent(payload) {
      this.submitNotePopup = false
      this.snackbar = true,
      this.snackbarColor = payload.color
      this.snackbarText = payload.text
    },
    closeSnackbar() {
      this.snackbar = false
    },
  },
  created() {
      this.$store.dispatch("MaintenanceJobs/getMaintenanceJobs");
  },
      filters: {
    toCurrency: function (value, currencyData) {

    if (typeof value !== "number") {

            return value;
        }
        if(currencyData != undefined){
        var formatter = new Intl.NumberFormat(currencyData.LocaleCode, {
            style: 'currency',
            currency: currencyData.CurrencyCode,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });

        return formatter.format(value);
        }
        else{

          return null;
        }
    },
    formatDate: function(value){
      return moment(String(value)).format('DD/MM/YYYY');
    }
  }
}
</script>

<style scoped>
.maintenanceCell{
  width: 10% !important;
  text-align: center !important;
}

</style>
