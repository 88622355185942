// src/plugins/vuetify.js

import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify);

const opts = {
  icons: {
    iconfont: 'mdi'
  },
  theme: {
    themes: {
      light: {
         primary: '#f26642',
        //primary: '#eb5c37',
         secondary: '#343351',
        //secondary: '#40424c',
        accent: '#8c9eff',
        error: '#b71c1c',
      },
      // dark: {
      //   primary: '#343351',
      //   secondary: '#f26642',
      //   accent: '#8c9eff',
      //   error: '#b71c1c',
      // },
    },
  },
}

export default new Vuetify(opts)
