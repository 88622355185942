import { API } from 'aws-amplify'

export default {
  namespaced: true,
  state: {
    maintenanceJobs: '',
    maintenanceJobsLoadedState : false
  },
  mutations: {
    SET_MAINTENANCE_JOBS(state, data){
      state.maintenanceJobs = data;
    },
    SET_MAINTENANCE_JOBS_LOAD_STATE(state, data){
      state.maintenanceJobsLoadedState = data;
    }
  },
	actions :  {
      getMaintenanceJobs({commit, rootState}){
        commit('SET_MAINTENANCE_JOBS_LOAD_STATE', false);
        API.get('contractorAppMainRest', `/get?query=/${rootState.selectedAccount.CompanyShortName}/maintenance/${rootState.selectedAccount.PersonOID}/jobs`)
        .then(res => {
          commit('SET_MAINTENANCE_JOBS', res.data)
          commit('SET_MAINTENANCE_JOBS_LOAD_STATE', true);
        })
        },
        markJobComplete({dispatch, rootState}, payload){
          API.get('contractorAppMainRest', `/get?query=/${rootState.selectedAccount.CompanyShortName}/maintenance/${rootState.selectedAccount.PersonOID}/jobs/${payload.jobID}/complete`)
            .then(function(){
              dispatch('getMaintenanceJobs');
              }
            )
        },
        markJobIncomplete({dispatch, rootState}, payload){
          API.get('contractorAppMainRest', `/get?query=/${rootState.selectedAccount.CompanyShortName}/maintenance/${rootState.selectedAccount.PersonOID}/jobs/${payload.jobID}/incomplete`)
          .then(function(){
            dispatch('getMaintenanceJobs');
            }
          )
        },
        submitMaintenanceJobNote({rootState}, payload){
          const postParams = {
            body: {
              Note: payload.Note
            }
          }
          API.post('contractorAppMainRest', `/post?query=/${rootState.selectedAccount.CompanyShortName}/maintenance/${rootState.selectedAccount.PersonOID}/jobs/${payload.JobID}/note`, postParams)
        },
        downloadJobsheet({rootState}, payload){

          API.get('contractorAppMainRest', `/get?query=/${rootState.selectedAccount.CompanyShortName}/download/${rootState.selectedAccount.PersonOID}/jobsheet/${payload.JobID}`)
          .then(res => {
            const byteCharacters = atob(res.data.Content);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
  
            const blob = new Blob([byteArray], {type: res.data.ContentType});
  
  
            var fileURL = window.URL.createObjectURL(blob);
            var fileLink = document.createElement('a');
            fileLink.href = fileURL;
            fileLink.setAttribute('download', `${payload.Filename}.pdf`);
            document.body.appendChild(fileLink);
  
            fileLink.click();
          });
        }
  },
  getters:{
    maintenanceJobs: state => {
      return state.maintenanceJobs.Data;
    },
    maintenanceJobsLoadedState: state => {
      return state.maintenanceJobsLoadedState;
    }
    
  }
}
