<template>

    <div>
        <v-card class="mb-6">
            <v-card-title class="secondary white--text pa-3" id="card-title">
            <div class="">
                <v-icon class="mr-3" color="white" >mdi-office-building-outline</v-icon>
                <span class="">Branch Contact Details</span>
            </div>
            </v-card-title>
            <div v-if="!branchContactsLoaded">
                <LoadingTable />
            </div>
           
            <v-data-table v-else class="summaryTable"
                :headers="branchContactsHeaders"
                :items="branchDetails"
                :items-per-page="10"
                :mobile-breakpoint="768"
                
            >  
      <!-- Reported At Column -->
      <template v-slot:[`item.name`]="{ item }">
        {{item.Name}}
      </template>

          <!-- Address Column -->
      <template v-slot:[`item.landline`]="{ item }">
        <a v-bind:href="'tel:' + item.Landline">{{ item.Landline }}</a>
      </template>
      <!-- Description Column -->
      <template v-slot:[`item.email`]="{ item }">
        <a v-bind:href="'mailto:' + item.Email">{{ item.Email }}</a>
      </template>
        </v-data-table>
        </v-card>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import LoadingTable from '../components/SkeletonLoaders/SkeletonLoaderTable.vue'

export default {
  components:{
    LoadingTable
  },
  data() {
    return {
        branchContactsHeaders: [
          { text: 'Name', value:'Name', align:"center" },
          { text: 'Landline', value: 'Landline', align:"center" },
          { text: 'Email', value: 'email', align:"center" },
        ],
        branchDetailsItems: this.branchDetails
    }
  },
  computed: {
    ...mapGetters({
        branchDetails: "Contacts/branchContactDetails",
        branchContactsLoaded : "Contacts/branchContactsLoaded"
    }),
  },
    created() {
      this.$store.dispatch("Contacts/getBranchContactDetails");
  },
}
</script>