<template>
<div>
  <div class="spinner-container" v-if='accountLoadingStatus && !loginFailure'>
    <vue-spinner size="massive" message="Loading your accounts, this can take up to 30 seconds. Please do not refresh this page."/>
  </div>
  <div v-else-if='noAccounts'><span class="font-weight-light" style="color:rgb(85, 85, 85)"
                  :class="{'body-1': $vuetify.breakpoint.xsOnly,'headline': $vuetify.breakpoint.smAndUp}">We could not find any accounts linked to this email address, please contact your client agency to confirm your contractor record has been set up.</span>
                </div>
  <div v-else-if='loginFailure'>
    <span lass="font-weight-light" style="color:rgb(85, 85, 85)"
                  :class="{'body-1': $vuetify.breakpoint.xsOnly,'headline': $vuetify.breakpoint.smAndUp}">Oops there's been an error logging in please refresh the page to try again. If the issue persists please contact agentOS support.</span>
  </div>
  <div v-else>
          <v-row>
              <v-col class="" cols="6" sm="8">
                <span 
                  class="font-weight-light grey--text text-capitalize" 
                  :class="{'body-1': $vuetify.breakpoint.xsOnly,'headline': $vuetify.breakpoint.smAndUp}"
                >Select your contractor account</span>
              </v-col>
        </v-row>
        <v-row>
              <v-col class="" cols="12">
                <span 
                  class="font-weight-light grey--text text-capitalize" 
                  :class="{'body-1': $vuetify.breakpoint.xsOnly,'headline': $vuetify.breakpoint.smAndUp}"
                >Accounts Last Synchronised: {{this.$store.state.lastAccountSyncDate | formatDate()}}</span>
              </v-col>
        </v-row>
        <v-row>
              <v-col class="" cols="12">
                <p class="font-weight-light grey--text" >The list of your accounts on this page is synchronised every 7 days. If an account you are expecting to see here is not showing or is displaying outdated information then you can manually refresh these details by clicking <v-icon>mdi-reload</v-icon> on the bottom right. Any details shown within an account will show live information.</p>
              </v-col>
        </v-row>
        <v-row>
            <v-col col="12" sm="12" md="6" v-for="item in accounts" :key="item.PersonOID">
                            <AccountCard :account="item"/>
            </v-col>
        </v-row>
        <v-btn
        class="mx-2 refresh-accounts-button"
        fab
        dark
        color="#343351"
        @click="resynchroniseAccounts()"
      > 
        <v-icon dark>
          mdi-reload
        </v-icon>
      </v-btn>
    </div>
</div>
</template>
<script>
import AccountCard from '../components/AccountCard.vue'
import Spinner from 'vue-simple-spinner'
import moment from 'moment'

import {mapGetters} from 'vuex'

export default {
  components: {
    AccountCard,
    vueSpinner: Spinner
  },
    created() {
      this.$store.dispatch("getUserAccounts");
  },
  methods:{
    resynchroniseAccounts(){
      this.$store.dispatch("resynchroniseAccounts");
    }
  },
  computed: {
    ...mapGetters({
      accounts : 'userAccounts',
      accountLoadingStatus: 'accountLoadingStatus',
      loginFailure: 'hasLoginFailed',
      noAccounts : 'noAccounts'
    }),
  },
    filters: {
    formatDate: function(value){
      return moment(String(value)).format('DD/MM/YYYY');
    }
  }
}
</script>
<style scoped>
.spinner-container{
  margin: auto;
  width: 50%;
  margin-top: 5%;
}
.refresh-accounts-button{
    position: fixed;
    bottom: 80px;
    right: 30px;
    z-index: 1; 
}
</style>