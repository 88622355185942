<template>
    <div>
        <v-card class="mb-6">
            <v-card-title class="secondary white--text pa-3" id="card-title">
            <div class="">
                <v-icon class="mr-3" color="white" >mdi-receipt</v-icon>
                <span class="">Submitted Invoices</span>
            </div>
            </v-card-title>
            <div v-if="!invoicesLoaded">
            <LoadingTable />
            </div>
            <v-data-table v-else class="summaryTable"
                :headers="invoiceHeaders"
                :items="invoices"
                :items-per-page="10"
                :mobile-breakpoint="768"
                :search="search"
            > 
      <template v-slot:top>
        <v-text-field
          v-model="search"
          label="Search"
          class="mx-4"
        ></v-text-field>
      </template>
      <template v-slot:[`item.Created`]="{ item }">
        {{item.Created | formatDate() }}
      </template>
      <template v-slot:[`item.JobNumber`]="{ item }">
        {{item.JobNumber }}
      </template>

      <template v-slot:[`item.Address`]="{ item }">
        {{item.Address}}
      </template>

      <template v-slot:[`item.Amount`]="{ item }">
        {{item.Amount | toCurrency(currencyValues)}}
      </template>

      <template v-slot:[`item.State`]="{ item }">
        <span :class="getStatusColourCoding(item.State)">{{item.State}} </span>
      </template>

      <template v-slot:[`item.documents`]="{ item }">
        
            <v-btn v-if="showDocuments(item.Documents)"                                 
                            text 
                            color="grey darken-1"
                            width="90"
                            @click="openDocumentPopup(item.Documents)"
                          >
                            <v-icon color="green">mdi-file-outline</v-icon>
            </v-btn>
            <v-btn v-else text width="90">
            <v-icon color="red">mdi-file-excel-outline</v-icon>
            </v-btn>

      </template>
        </v-data-table>
        <p class="description">*Authorised and Rejected invoices will only be retained for 90 days from the date they were submitted. Submitted invoices will be retained longer if no action has been taken on them within the 90 day period.</p>
        </v-card>
      <DocumentPopup
          :dialog="documentDialog"
          :documents="selectedInvoiceDocuments"
          @closeDialog="closeDocumentPopup"
        />
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import moment from 'moment'
import DocumentPopup from '../components/DocumentsPopupComponent.vue'
import LoadingTable from '../components/SkeletonLoaders/SkeletonLoaderTable.vue'

export default {
  components:{
    DocumentPopup,
    LoadingTable
  },
  data() {
    return {
        invoiceHeaders: [
          { text: 'Posted At', value:'Created', class:"maintenanceCell", align:"center", sortable:true },
          { text: 'Job Number', value:'JobNumber', class:"maintenanceCell", align:"center", sortable:true },
          { text: 'Address', value: 'Address', class:"maintenanceCell", align:"center", sortable:true },
          { text: 'Amount', value: 'Amount', class:"maintenanceCell", align:"center", sortable: true, sort: (a, b) => b - a },
          { text: 'State', value: 'State', class:"maintenanceCell", align:"center", sortable: true, sort: (a, b) =>this.itemValues(b) - this.itemValues(a) },
          { text: 'Documents', value: 'documents', class:"maintenanceCell", align:"center", sortable: false}
        ],
        documentDialog: false,
        selectedInvoiceDocuments: [],
        search: ''
    }
  },
  computed: {
    ...mapGetters({
        invoices: "Invoices/submittedInvoices",
        currencyValues : 'currencyInformation',
        invoicesLoaded: "Invoices/invoicesLoaded"
    }),
  },
  created() {
      this.$store.dispatch("Invoices/getSubmittedInvoices");
  },
  methods:{
    itemValues(item){
      if(item === "Authorised")
        return 3;
      if(item === "Submitted")
        return 2;
      if(item === "Rejected")
        return 1;
    },
      getStatusColourCoding(value){
        
        if(value === "Submitted"){
          return "orangeText";
        }
        else if(value === "Authorised"){
          return "greenText";
        }
        else if(value === "Rejected"){
          return "redText";
        }
      },
      showDocuments(docs){
        if(Array.isArray(docs) && docs.length){
          return true;
        }
        else{
          return false;
        }

      },
      closeDocumentPopup(){
        this.selectedInvoiceDocuments = [],
        this.documentDialog = false;
      },
      openDocumentPopup(documents){
        this.selectedInvoiceDocuments = documents,
        this.documentDialog = true
      }
  },
      filters: {
    toCurrency: function (value, currencyData) {

    if (typeof value !== "number") {

            return value;
        }
        if(currencyData != undefined){
        var formatter = new Intl.NumberFormat(currencyData.LocaleCode, {
            style: 'currency',
            currency: currencyData.CurrencyCode,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });

        return formatter.format(value);
        }
        else{

          return null;
        }
    },
    formatDate: function(value){
      return moment(String(value)).format('DD/MM/YYYY');
    }
    
  }
}
</script>
<style scoped>
.greenText{
  color:green;
}
.orangeText{
  color:orange;
}
.redText{
  color:red;
}
</style>