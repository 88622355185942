<template>
  <div>
      <v-footer
        absolute
        padless
        class="grey lighten-2 font-weight-medium"
      >
        <v-col cols="12" class=" footer-column text-center" >
          
            <p class="footer-text overline my-0">{{ new Date().getFullYear() }} -&#32;<a href="https://www.agentos.com/contractorapp" target="_blank" >contractorApp</a>&#32;by&#32;<a href="https://www.agentos.com" target="_blank">agentOS</a></p>

        </v-col>
      </v-footer>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
  computed: {
    ...mapGetters(['getCompany'])
  },
}
</script>
<style scoped>
      @media (min-width: 1264px) {
        .footer-column{
          margin-top:10px;
          margin-left:90px;
        }
    }
    .footer-text{
      margin-bottom:10px;
      text-align: center;
      white-space: normal;
    }
    .v-application .overline{
      text-transform: none;
    }
</style>

