<template>
<div>
      <SubmitInvoiceComponent />
    </div>
</template>
<script>
import SubmitInvoiceComponent from '../components/SubmitInvoiceComponent.vue'

export default {
  components: {
    SubmitInvoiceComponent
  }
}
</script>