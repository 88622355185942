/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createContractorAccount = /* GraphQL */ `
  mutation CreateContractorAccount(
    $input: CreateContractorAccountInput!
    $condition: ModelContractorAccountConditionInput
  ) {
    createContractorAccount(input: $input, condition: $condition) {
      id
      PersonOID
      CompanyName
      CreatedDate
      CompanyShortName
      FirstName
      Surname
      GlobalReference
      ContractorCompany
      ManagingBranch
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateContractorAccount = /* GraphQL */ `
  mutation UpdateContractorAccount(
    $input: UpdateContractorAccountInput!
    $condition: ModelContractorAccountConditionInput
  ) {
    updateContractorAccount(input: $input, condition: $condition) {
      id
      PersonOID
      CompanyName
      CreatedDate
      CompanyShortName
      FirstName
      Surname
      GlobalReference
      ContractorCompany
      ManagingBranch
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteContractorAccount = /* GraphQL */ `
  mutation DeleteContractorAccount(
    $input: DeleteContractorAccountInput!
    $condition: ModelContractorAccountConditionInput
  ) {
    deleteContractorAccount(input: $input, condition: $condition) {
      id
      PersonOID
      CompanyName
      CreatedDate
      CompanyShortName
      FirstName
      Surname
      GlobalReference
      ContractorCompany
      ManagingBranch
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createContractorAccountSyncDateTime = /* GraphQL */ `
  mutation CreateContractorAccountSyncDateTime(
    $input: CreateContractorAccountSyncDateTimeInput!
    $condition: ModelContractorAccountSyncDateTimeConditionInput
  ) {
    createContractorAccountSyncDateTime(input: $input, condition: $condition) {
      id
      lastUpdate
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateContractorAccountSyncDateTime = /* GraphQL */ `
  mutation UpdateContractorAccountSyncDateTime(
    $input: UpdateContractorAccountSyncDateTimeInput!
    $condition: ModelContractorAccountSyncDateTimeConditionInput
  ) {
    updateContractorAccountSyncDateTime(input: $input, condition: $condition) {
      id
      lastUpdate
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteContractorAccountSyncDateTime = /* GraphQL */ `
  mutation DeleteContractorAccountSyncDateTime(
    $input: DeleteContractorAccountSyncDateTimeInput!
    $condition: ModelContractorAccountSyncDateTimeConditionInput
  ) {
    deleteContractorAccountSyncDateTime(input: $input, condition: $condition) {
      id
      lastUpdate
      createdAt
      updatedAt
      owner
    }
  }
`;
