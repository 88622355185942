import { API } from 'aws-amplify'

export default {
  namespaced: true,
  state: {
    accountSummary: '',
    remittances: '',
    accountSummaryLoadedState : false,
    remittancesLoadedState : false
  },
  mutations: {
    SET_ACCOUNT_SUMMARY(state, data) {
      state.accountSummary = data
    },
    SET_REMITTANCES(state, data){
      state.remittances = data;
    },
    SET_ACCOUNT_SUMMARY_LOAD_STATE(state, data){
      state.accountSummaryLoadedState = data;
    },
    SET_REMITTANCES_LOAD_STATE(state, data){
      state.remittancesLoadedState = data;
    }
  },
	actions :  {

    getAccountSummary({commit, rootState}){
      commit('SET_ACCOUNT_SUMMARY_LOAD_STATE', false);
      API.get('contractorAppMainRest', `/get?query=/${rootState.selectedAccount.CompanyShortName}/finance/${rootState.selectedAccount.PersonOID}/summary`)
      .then(res => {
        commit('SET_ACCOUNT_SUMMARY', res.data)
        commit('SET_ACCOUNT_SUMMARY_LOAD_STATE', true);
      })
      },
      getRemittances({commit, rootState}){
        commit('SET_REMITTANCES_LOAD_STATE', false);
        API.get('contractorAppMainRest', `/get?query=/${rootState.selectedAccount.CompanyShortName}/finance/${rootState.selectedAccount.PersonOID}/remittances`)
        .then(res => {
          commit('SET_REMITTANCES', res.data);
          commit('SET_REMITTANCES_LOAD_STATE', true);
        })
        },
      downloadInvoice({rootState}, payload){
        API.get('contractorAppMainRest', `/get?query=/${rootState.selectedAccount.CompanyShortName}/download/${rootState.selectedAccount.PersonOID}/invoice/${payload.OID}`)
        .then(res => {
          const byteCharacters = atob(res.data.Content);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);

          const blob = new Blob([byteArray], {type: res.data.ContentType});


          var fileURL = window.URL.createObjectURL(blob);
          var fileLink = document.createElement('a');
          fileLink.href = fileURL;
          fileLink.setAttribute('download', `${payload.GlobalRef}.pdf`);
          document.body.appendChild(fileLink);

          fileLink.click();
        })
      },
  },
  getters:{
    remittanceRecords: state => {
      return state.remittances.Data;
    },
    pendingInvoice: state => {
      return state.accountSummary.PendingInvoice;
    },
    summaryLoaded : state => {
      return state.accountSummaryLoadedState;
    },
    remittancesLoaded : state => {
      return state.remittancesLoadedState;
    }
    
  }
}
