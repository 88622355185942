<template>
<div>
      <InvoiceTableComponent />
    </div>
</template>
<script>
import InvoiceTableComponent from '../components/InvoiceTableComponent.vue'

export default {
  components: {
    InvoiceTableComponent,
  },
}
</script>