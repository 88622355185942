<template>
  <v-dialog
      v-model="dialog"
      @shown="refreshCarousel()"
      max-width="600px"
    >
      <v-card>
        <v-card-title class="title primary white--text font-weight-light px-4">
          Job Photos
          <v-spacer></v-spacer>
          <v-btn @click="closeDialog()" text color="white">
            <v-icon right>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <carousel :per-page="1"
          :min-swipe-distance="1">
        <slide v-for="photo in photos" :key="photo">
            <img v-bind:src ="photo" />
        </slide>
        </carousel>
      </v-card>
    </v-dialog>
</template>

<script>
import {mapState} from 'vuex'

export default {
  props: [
    'dialog',
    'photos'
  ],
  data() {
    return {
        frequency: 1,
    }
  },
  methods: {
    closeDialog() {
      this.$emit('closeDialog');
    },
    refreshCarousel(){
    this.frequency++
    }
  },
  computed:{
    ...mapState({
      summary: state => state.Finances.accountSummary
    })
  },
}
</script>
<style scoped>
img{
    max-height:500px;
    max-width:500px;
    height:auto;
    width:auto;
}
</style>