<template>
    <div>
        <v-card class="mb-6">
            <v-card-title class="secondary white--text pa-3" id="card-title">
            <div class="">
                <v-icon class="mr-3" color="white" >mdi-history</v-icon>
                <span class="">Paid Invoices</span>
            </div>
            </v-card-title>
            <div v-if="!remittancesLoaded">
              <LoadingTable />
            </div>
            <v-data-table v-else class="summaryTable"
                :headers="remittanceHeaders"
                :items="remittanceRecords"
                :items-per-page="10"
                
            >
            
                <template v-slot:item="props">

                    <tr>
                    <td class="summaryCell">{{ props.item.Date | formatDate() }}</td>
                                        <td class="text-center">    <v-btn 
                                                    
                                                    @click="downloadInvoice(props.item.OID, props.item.InvoiceGlobalRef)"
                                                    text 
                                                    color="grey"
                                                    width="90"
                                                  >
                                                    
                                                    <span  class="caption font-weight-light">
                                                      {{props.item.InvoiceGlobalRef}}
                                                      </span>
                                                      <v-icon right>mdi-file-download-outline</v-icon>
                                              
                                                  </v-btn>
                        </td>
                    <td class="summaryCell">{{ props.item.Amount | toCurrency(currencyValues) }}</td>
                    </tr>

                </template>
            </v-data-table>





            
                <p class="description">*Paid invoices are only available for the past 24 months</p>

        </v-card>
    </div>
</template>

<script>
import moment from 'moment'
import {mapGetters} from 'vuex'
import LoadingTable from '../components/SkeletonLoaders/SkeletonLoaderTable.vue';
export default {
  components:{
    LoadingTable
  },
  data() {
    return {
        remittanceHeaders: [
          {
            text: 'Date', value:'Date', class:"summaryCell", align:"center", sortable:true
          },
          { text: 'Remittance', value: 'remittance', class:"summaryCell", align:"center", sortable: false },
          { text: 'Amount Paid', value: 'Amount', class:"summaryCell", align:"center", sortable:true, sort: (a, b) => b - a  },
        ],
    }
  },
  computed: {
    ...mapGetters({
      remittanceRecords : 'Finances/remittanceRecords',
      currencyValues : 'currencyInformation',
      remittancesLoaded : 'Finances/remittancesLoaded'
    }),
  },
  methods: {
    downloadInvoice: function(invoiceID, invoiceGlobalRef){
      const payload =  {
          OID: invoiceID,
          GlobalRef: invoiceGlobalRef
      }
      this.$store.dispatch("Finances/downloadInvoice", payload);
    }
  },
  created() {
      this.$store.dispatch("Finances/getRemittances");
  },
      filters: {
    toCurrency: function (value, currencyData) {

    if (typeof value !== "number") {

            return value;
        }
        if(currencyData != undefined){
        var formatter = new Intl.NumberFormat(currencyData.LocaleCode, {
            style: 'currency',
            currency: currencyData.CurrencyCode,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });

        return formatter.format(value);
        }
        else{

          return null;
        }
    },
    formatDate: function(value){
      return moment(String(value)).format('DD/MM/YYYY');
    }
  }
}
</script>

<style scoped>
.summaryCell{
  width: 33% !important;
  text-align: center !important;
}
</style>